import { Button } from '@cg/module-frontend/src/components';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useExperience } from '~/app/context/experience';
import { GrabTicket } from './GrabTicket';
import { PurchasedTickets } from './PurchasedTickets';

export function ExperienceTicketNav() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  if (!/^\/e\/[^/]+$/.test(pathname)) {
    return null;
  }

  const { isPast, saleEnded, purchasedTickets } = useExperience();

  return (
    <div className="flex flex-col w-full bottom-12 fixed left-0 z-50 shadow-[0_10px_5px_10px_rgba(0,0,0,0.3)]">
      {(isPast || saleEnded) && (
        <div className="relative flex flex-row justify-evenly bg-primary h-full w-full py-6">
          <div className="items-center justify-center flex flex-row gap-x-2 text-base text-white font-semibold">
            Sales Ended
          </div>
          <Button
            color="secondary"
            className="hover:border-white"
            onClick={() => navigate('/')}
          >
            <span>Explore Experiences</span>
          </Button>
        </div>
      )}
      {!isPast &&
        !saleEnded &&
        (purchasedTickets && purchasedTickets.length !== 0 ? (
          <div className="flex flex-row justify-center bg-primary h-full w-full">
            <PurchasedTickets purchasedTickets={purchasedTickets} />
          </div>
        ) : (
          <div className="relative">
            {/* <SaleEndDate /> */}
            <div className="flex flex-row justify-evenly bg-primary h-full w-full">
              <GrabTicket />
            </div>
          </div>
        ))}
    </div>
  );
}
