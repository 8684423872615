import { ModalPage, MultiPageModal } from '@cg/module-frontend/src/components';
import * as React from 'react';

import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';

import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';
import { ModalState } from './types';
import showSelectionsModalPage from './showSelectionModalPage';
import loginModalPage from './loginModalPage';
import guestInfoModalPage from './guestInfoModalPage';
import successModalPage from './successModal';
import autoRegisterModalPage from './autoRegisterModalPage';

type Props = {
  experiences: DetailedExperience[];
  series: DetailedExperienceSeries;
  show: boolean;
  onClose: () => void;
  initialPage?: string;
  initialState?: DetailedExperience[];
};

function SignupRunModal({
  series,
  experiences,
  show,
  onClose,
  initialPage,
  initialState,
}: Props) {
  const modalPages: ModalPage<ModalState>[] = [
    showSelectionsModalPage(experiences, onClose),
    loginModalPage(),
    guestInfoModalPage(),
    successModalPage(onClose),
    autoRegisterModalPage(),
  ];

  return (
    <MultiPageModal
      initialState={{
        series,
        added: initialState ?? [],
        removed: [],
      }}
      open={show}
      onClose={onClose}
      pages={modalPages}
      initialPage={initialPage ?? 'selection'}
      position="center"
      size="xl"
    />
  );
}

SignupRunModal.displayName = 'SignupRunModal';

export default SignupRunModal;
