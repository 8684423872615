import {
  Accordion,
  Checkbox,
  Map,
  ModalPage,
} from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { useState } from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import {
  ClockIcon,
  DistanceIcon,
  LocationIcon,
  TimerIcon,
} from '@cg/module-frontend/src/icons';
import { getAddressString } from '@cg/module-frontend/src/hooks/address.hooks.ts';
import * as React from 'react';
import {
  calculateDistance,
  calculateDistanceRangeDom,
  calculatePace,
  calculatePaceRangeDom,
} from '@cg/module-frontend/src/hooks/series.hooks';
import { ModalState } from './types';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';
import { useHostPage } from '~/pages/hosts/host/context';
import { FamiliarFacesSummary } from '~/components';

export default function showSelectionsModal(
  experiences: DetailedExperience[],
  cancel: () => void,
): ModalPage<ModalState> {
  const {
    registerForRun,
    fetchTicketTiers,
    registering,
    attendingExperiences,
  } = useHostPage();
  const { isLogged, self } = useAuth();
  const [selected, setSelected] = useState<number[]>([]);
  const toggle = (experience: DetailedExperience, index: number) => {
    fetchTicketTiers(experience.id);
    if (selected.includes(index)) {
      setSelected(selected.filter((i) => i !== index));
    } else {
      setSelected([...selected, index]);
    }
  };

  return {
    header: 'Select Your Desired Days',
    body: (
      <>
        <div className="flex flex-col mb-2">You can select one or more.</div>
        <div className="flex flex-col space-y-4">
          {experiences.map((experience, index) => {
            const paces = calculatePace([experience.meta]);
            const distances = calculateDistance([experience.meta]);
            const paceRange = calculatePaceRangeDom(paces);
            const distanceRange = calculateDistanceRangeDom(distances);
            const { address } = experience;

            const isAttending = attendingExperiences.some((e) =>
              e.id.isEqual(experience.id),
            );

            return (
              <div
                key={experience.id.getValue()}
                className="flex flex-col border border-grey py-2 px-4 rounded"
              >
                <div className="flex items-center justify-between mb-3">
                  <h4>
                    {DateUtils.dater(experience.startDate).format(
                      'dddd, MMM Do',
                    )}
                  </h4>
                  <Checkbox
                    checked={selected.includes(index) || isAttending}
                    disabled={isAttending}
                    onChange={() => toggle(experience, index)}
                  />
                </div>

                <div className="flex justify-between text-primary">
                  <div className="flex items-center space-x-1">
                    <ClockIcon className="text-primary stroke-primary size-4" />
                    <span className="text-sm font-normal">
                      {DateUtils.dater(experience.startDate).format('h:mm A')}
                    </span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <TimerIcon className="text-primary stroke-primary size-4" />
                    <span className="text-sm font-normal">{paceRange}</span>
                  </div>

                  <div className="flex items-center space-x-1">
                    <DistanceIcon className="text-primary stroke-primary size-4" />
                    <span className="text-sm font-normal">{distanceRange}</span>
                  </div>
                </div>

                {address && (
                  <Accordion className="mt-2" flush={false} collapseAll>
                    <Accordion.Panel isOpen={false}>
                      <Accordion.Title>
                        <div className="text-primary flex items-center space-x-2">
                          <LocationIcon className="stroke-primary size-4" />
                          <span className="text-sm font-normal">
                            {getAddressString(address, {
                              includeName: true,
                              shortHanded: true,
                            })}
                          </span>
                        </div>
                      </Accordion.Title>
                      <Accordion.Content>
                        <Map
                          latitude={address.latitude}
                          secret={experience.isSecret}
                          longitude={address.longitude}
                          address={address}
                        />
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                )}

                <FamiliarFacesSummary
                  className="!bg-grey mt-2"
                  users={experience.connections}
                />
              </div>
            );
          })}
        </div>
      </>
    ),
    name: 'selection',
    footer: {
      primary: {
        text: 'Continue',
        disabled: selected.length === 0,
        loading: registering,
        action: async (nextPage, state) => {
          const added = experiences.filter((_, index) =>
            selected.includes(index),
          );

          if (isLogged && self) {
            await registerForRun(
              self.user,
              added.map((e) => e.id),
            );
            nextPage('success', {
              ...state,
              added,
            });
          } else {
            nextPage('login', {
              ...state,
              added,
            });
          }
        },
      },
      secondary: {
        disabled: registering || registering,
        text: 'Cancel',
        action: cancel,
      },
    },
  };
}
