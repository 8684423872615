import { Loader } from '@cg/module-frontend/src/components';
import { useHostLayout } from '~/app/context/host';
import HostExperiencesPage from './HostExperiencesPage';
import { HostExperiencesPageContextProvider } from '~/pages/hosts/host/experiences/context';

export default function HostExperiences() {
  const { host, fetchingHost } = useHostLayout();

  return (
    <HostExperiencesPageContextProvider>
      <Loader loading={fetchingHost}>
        {host && <HostExperiencesPage host={host} />}
      </Loader>
    </HostExperiencesPageContextProvider>
  );
}
