import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Content, Page } from '@cg/module-frontend/src/components';
import { ExperiencesCarrousel } from '~/components';
import { Heading } from '../components/Heading';
import { useExperience } from '~/app/context/experience';
import Result from '~/pages/experience/confirmation/components/Result';
import MessageFromHost from '~/pages/experience/confirmation/components/MessageFromHost';
import { fetchFailureConfirmationHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import { fetchHostHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';

function CheckoutFailed() {
  const { experience, fetchingExperience } = useExperience();
  const {
    data: confirmation,
    call: fetchConfirmation,
    calling: fetchingConfirmation,
  } = fetchFailureConfirmationHook(false);
  const {
    calling: fetchingHost,
    data: host,
    call: fetchHost,
  } = fetchHostHook(false);

  useEffect(() => {
    if (experience) {
      fetchHost({ ids: { hostId: experience.hostId } });
      fetchConfirmation({ ids: { experienceId: experience.id } });
    }
  }, [experience]);

  return (
    <Page>
      <Content>
        <Result
          loading={fetchingConfirmation || fetchingExperience || fetchingHost}
        >
          <Heading
            title={
              <>
                <span className="text-warning mr-2">⚠️</span>Oops, something
                went wrong<span className="text-warning ml-2">⚠️</span>
              </>
            }
          >
            <span>
              <Link to="/contact-us">Contact us</Link>
              {' and we can fix it!'}
            </span>
          </Heading>
          <MessageFromHost
            hideCTA
            host={host}
            experience={experience}
            confirmation={confirmation}
          />
        </Result>
        <ExperiencesCarrousel />
      </Content>
    </Page>
  );
}

export default CheckoutFailed;
