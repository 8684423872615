import React, { useMemo } from 'react';
import { Button, Loader } from '@cg/module-frontend/src/components';
import { PlusIcon } from '@cg/module-frontend/src/icons';
import RunClubCard from './RunClubCard.tsx';
import { ConsolidatedClub } from './runs.hooks.ts';

type Props = {
  clubs: ConsolidatedClub[];
  fetching: boolean;
  hasMore: boolean;
  showAll: () => void;
};

const RunClubsStack = React.memo(
  ({ clubs, fetching, hasMore, showAll }: Props) => {
    const cards = useMemo(() => {
      return (
        clubs.map(({ series, days, paces, distances }) => (
          <RunClubCard
            key={series.id.getValue()}
            series={series}
            days={days}
            paces={[paces[0], paces[paces.length - 1]]}
            distances={[distances[0], distances[distances.length - 1]]}
          />
        )) || []
      );
    }, [clubs, fetching]);

    return (
      <>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-4">
          <Loader className="w-full col-span-3" loading={fetching} horizontal />
          {cards}
        </div>

        {hasMore && (
          <Button onClick={showAll} color="secondary" className="w-full mt-4">
            <div className="flex flex-row items-center justify-center">
              <PlusIcon className="me-2" />
              <span>Show All</span>
            </div>
          </Button>
        )}
      </>
    );
  },
);

RunClubsStack.displayName = 'RunClubsStack';

export default RunClubsStack;
