import { createContext, useContext } from 'react';
import { Host } from '~/generated/models/Host';

export type HostLayoutContextType = {
  host: Host | null;
  fetchingHost: boolean;
};

export const HostLayoutContext = createContext<HostLayoutContextType>({
  host: null,
  fetchingHost: false,
});

export function useHostLayout() {
  return useContext(HostLayoutContext);
}
