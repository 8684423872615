import React, { useEffect } from 'react';
import { Content, Page } from '@cg/module-frontend/src/components';
import { ExperiencesCarrousel } from '~/components';
import { Heading } from '../components/Heading';
import { useExperience } from '~/app/context/experience';
import MessageFromHost from '~/pages/experience/confirmation/components/MessageFromHost';
import Result from '../components/Result';
import { fetchWaitlistConfirmationHook } from '~/generated/clients/private/experiences/PrivateExperiences.hooks';
import { fetchHostHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';

function Waitlisted() {
  const { experience, fetchingExperience, waitlisted } = useExperience();
  const {
    data: confirmation,
    call: fetchConfirmation,
    calling: fetchingConfirmation,
  } = fetchWaitlistConfirmationHook(false);
  const {
    calling: fetchingHost,
    data: host,
    call: fetchHost,
  } = fetchHostHook(false);

  useEffect(() => {
    if (experience) {
      fetchHost({ ids: { hostId: experience.hostId } });
    }
  }, [experience]);

  useEffect(() => {
    if (!experience) {
      return;
    }

    if (experience) {
      fetchConfirmation({ ids: { experienceId: experience.id } });
    }
  }, [waitlisted, experience]);

  return (
    <Page>
      <Content>
        <Result
          loading={fetchingExperience || fetchingConfirmation || fetchingHost}
        >
          <Heading title="You're on the waitlist! 🥳">
            <span>
              We&apos;ll drop you an email as soon as a ticket becomes
              available. But remember - it&apos;s first come, first served
              🎟️🏃💨.
            </span>
          </Heading>
          <MessageFromHost
            hideCTA
            host={host}
            confirmation={confirmation}
            experience={experience}
          />
        </Result>

        <ExperiencesCarrousel />
      </Content>
    </Page>
  );
}

export default Waitlisted;
