import { createContext, useContext } from 'react';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';

export type HostExperiencesPageContextType = {
  fetchingExperiences: boolean;
  experiences: DetailedExperience[];
};

export const HostExperiencesPageContext =
  createContext<HostExperiencesPageContextType>({
    fetchingExperiences: false,
    experiences: [],
  });

export function useHostExperiencesPage() {
  return useContext(HostExperiencesPageContext);
}
