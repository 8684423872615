import {
  Button,
  FormField,
  Modal,
  TextInput,
} from '@cg/module-frontend/src/components';
import React, { useMemo } from 'react';
import { useContactForm, useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail } from '@cg/common/src/utils';
import { Host } from '~/generated/models/Host.ts';

type ClaimClubRequest = {
  email: string;
  hostName: string;
  hostId: string;
  name: string;
};

const getForm = () => {
  return {
    email: '',
    name: '',
  };
};

type Props = {
  host: Host;
  show: boolean;
  onClose: () => void;
};
function ClaimClub({ show, onClose, host }: Props) {
  const { handleSubmit: contact } = useContactForm<ClaimClubRequest>({
    hostId: host.id.getValue(),
    hostName: host.uniqueName,
  });

  const [submitted, setSubmitted] = React.useState(false);
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ClaimClubRequest>({
    mode: 'onBlur',
    defaultValues: useMemo(() => getForm(), []),
  });

  const close = () => {
    reset();
    onClose();
    setSubmitted(false);
  };

  const submit = async () => {
    await contact(getValues());
    setSubmitted(true);
  };

  return (
    <Modal show={show} onClose={close}>
      <Modal.Header>Claim Your Run Club</Modal.Header>
      <Modal.Body>
        {submitted && "Received! We'll reach out soon to set you up."}
        {!submitted && (
          <>
            <div className="flex flex-col w-full mb-4">
              Track your views, enable sign-ups, manage your members, and more.
            </div>
            <div className="flex flex-col w-full space-y-4">
              <FormField
                id="name"
                label="Your Name"
                register={register}
                errors={errors}
                required
                registerOptions={{
                  validate: (value: string) => {
                    if (!value || value.length < 3) {
                      return "You're too cute to not have a first name ❤️";
                    }

                    return true;
                  },
                }}
              >
                <TextInput
                  minLength={3}
                  maxLength={32}
                  placeholder="Your Name"
                />
              </FormField>

              <FormField
                id="email"
                label="Email"
                register={register}
                errors={errors}
                required
                registerOptions={{
                  validate: (email?: string) => {
                    if (!email) {
                      return true;
                    }

                    if (isValidEmail(email)) {
                      return true;
                    }

                    return 'Please enter a valid email.';
                  },
                }}
              >
                <TextInput
                  minLength={4}
                  maxLength={64}
                  placeholder="Your Name"
                />
              </FormField>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {submitted && (
          <Button onClick={close} color="primary" className="ml-auto">
            Ok
          </Button>
        )}
        {!submitted && (
          <Button
            onClick={handleSubmit(submit)}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            color="primary"
            className="ml-auto"
          >
            Claim
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ClaimClub.displayName = 'ClaimClub';

export default ClaimClub;
