import React, { useState } from 'react';
import UserAvatar from './UserAvatar';
import { ArrowDownIcon } from '../../icons';

type UserBadgeProps = {
  user: {
    firstName?: string | null;
    lastName?: string | null;
    image?: string | null;
  };
  collapsable?: boolean;
  onToggle?: (open: boolean) => void;
  openChildren?: React.ReactElement | React.ReactElement[];
  children?: React.ReactNode;
  hideName?: boolean;
};

function UserPill({
  user,
  children,
  collapsable,
  onToggle,
  openChildren,
  hideName,
}: UserBadgeProps) {
  const [open, setOpen] = useState(false);

  const wrapperClassName =
    'flex justify-between items-center space-x-4 hover:underline';

  const getPill = () => {
    const content = () => {
      return (
        <>
          <div className="flex space-x-2 items-center p-2">
            <UserAvatar user={user} iconSize="md" imageSize="md" expandable />
            {!hideName && (
              <span className="text-base font-medium">{user.firstName}</span>
            )}
          </div>
          <div className="flex flex-row space-x-2 justify-center items-center">
            {children}
            {collapsable && (
              <ArrowDownIcon
                className={`${!open && '-rotate-90 justify-self-center'} text-primary stroke-primary`}
              />
            )}
          </div>
        </>
      );
    };

    if (collapsable) {
      return content();
    }

    return <div className={wrapperClassName}>{content()}</div>;
  };

  return (
    <div className="flex flex-col bg-white rounded pr-2">
      {!collapsable && getPill()}
      {collapsable && (
        <button
          type="button"
          className={wrapperClassName}
          onClick={() => {
            const newState = !open;
            setOpen(newState);
            if (onToggle) {
              onToggle(newState);
            }
          }}
        >
          {getPill()}
        </button>
      )}
      {open && openChildren}
    </div>
  );
}

export default UserPill;
