import React, { cloneElement } from 'react';
import { HelperText } from 'flowbite-react';

import {
  FieldValues,
  FieldErrors,
  UseFormRegister,
  RegisterOptions,
  Path,
  Controller,
  Control,
} from 'react-hook-form';

import classNames from 'classnames';
import Label from './Label';

export type Props<
  TFieldValues extends FieldValues,
  Id extends Path<TFieldValues>,
> = {
  id: Id;
  icon?: React.ReactNode;
  inline?: boolean;
  className?: string;
  control?: Control<TFieldValues>;
  required?: boolean;
  label?: React.ReactNode;
  additionalInfo?: React.ReactNode;
  helperText?: React.ReactNode;
  errors?: FieldErrors<TFieldValues>;
  register?: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions<TFieldValues, Id>;
  children: React.ReactElement;
  insertAfter?: React.ReactNode;
};

export default function FormField<
  TFieldValues extends FieldValues,
  Id extends Path<TFieldValues>,
>({
  id,
  icon,
  inline,
  required,
  additionalInfo,
  label,
  helperText,
  errors,
  register,
  registerOptions,
  children,
  className,
  control,
  insertAfter,
}: Props<TFieldValues, Id>) {
  if (!register) {
    throw new Error(
      '`register` must be provided if FormField is used outside of Form.',
    );
  }

  const childHelperText = errors?.[id]?.message ? (
    // @ts-ignore
    <span className="text-failure flex mt-2 ml-2 ">{errors[id]?.message}</span>
  ) : null;

  const extendedChild = cloneElement(children, {
    ...register(id, registerOptions),
    id,
    required,
  });

  const controllerChild = control ? (
    <Controller
      name={id}
      control={control}
      rules={registerOptions}
      render={({ field }) =>
        cloneElement(children, {
          value: field.value,
          onChange: field.onChange,
          onBlur: field.onBlur,
          name: field.name,
          disabled: children.props.disabled,
          placeholder: children.props.placeholder,
        })
      }
    />
  ) : null;

  return (
    <div className={className}>
      <div
        className={classNames({
          'flex flex-col': !inline,
          'items-center flex flex-row justify-between': inline,
        })}
        key={id}
      >
        <div className="w-full flex flex-row items-center space-x-1">
          {icon}
          <Label
            htmlFor={id}
            className="px-2 w-full"
            required={required ?? false}
            additionalInfo={additionalInfo}
          >
            {label}
          </Label>
        </div>
        {helperText && (
          <HelperText className="ml-2 mb-2">{helperText}</HelperText>
        )}
        {control ? controllerChild : extendedChild}
        {insertAfter}
      </div>
      {childHelperText}
    </div>
  );
}
