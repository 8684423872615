import React, { useEffect } from 'react';
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import {
  Collapse,
  Content,
  DesktopScreen,
  MobileScreen,
  Page,
  Section,
} from '@cg/module-frontend/src/components';
import { RunIcon, TicketIcon, UserIcon } from '@cg/module-frontend/src/icons';
import { useAuth } from '@cg/module-frontend';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import { UserInformation } from './UserInformation';
import ProfileTab from './ProfileTab.tsx';
import { ActiveProfileTab } from './types';

function useProfilePathParam(): ActiveProfileTab | null {
  const matches = useMatches();
  const profileMatch = matches.find((match) =>
    match.pathname.includes('/profile/'),
  );

  if (profileMatch) {
    const regex = /\/profile\/(\w+)/;
    const match = profileMatch.pathname.match(regex);

    if (match?.[1]) {
      const profileKey = match[1] as ActiveProfileTab;
      // if (MAP[profileKey]) {
      //   return profileKey;
      // }
      return profileKey;
    }
  }

  return null;
}

export default function ProfileLayout() {
  const activeTab = useProfilePathParam();
  const { isSmall, isMobile } = useScreenSize();
  const { self } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const desktop = !isSmall && !isMobile;

  const toTab = (tab: ActiveProfileTab) => {
    if (tab !== activeTab) {
      navigate(`/profile/${tab}`);
      return;
    }

    if (tab === activeTab && !desktop) {
      navigate('/profile');
    }
  };

  useEffect(() => {
    const normalizedPathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname;

    if (desktop && normalizedPathname === '/profile') {
      navigate('/profile/experiences');
    }
  }, [desktop, location.pathname, navigate]);

  return (
    <>
      <DesktopScreen>
        <Page className="hidden md:block">
          <Content>
            <Section className="pb-9 flex px-2 bg-white rounded space-x-2 flex-col">
              <div className="rounded border w-full px-2 pb-1">
                <div className="w-full px-2 items-center justify-center flex flex-col">
                  <ul className="flex flex-wrap -mb-px text-sm font-medium text-center items-center w-full justify-end p-4">
                    <li className="me-2 mr-auto" role="presentation">
                      {self && <UserInformation self={self} />}
                    </li>
                    <li className="me-2" role="presentation">
                      <ProfileTab
                        onClick={() => toTab(ActiveProfileTab.Experiences)}
                        icon={<TicketIcon className="size-6" />}
                        title="My Experiences"
                        isActive={activeTab === ActiveProfileTab.Experiences}
                      />
                    </li>
                    <li className="me-2" role="presentation">
                      <ProfileTab
                        onClick={() => toTab(ActiveProfileTab.Runs)}
                        icon={<RunIcon className="size-6" />}
                        title="My Runs"
                        isActive={activeTab === ActiveProfileTab.Runs}
                      />
                    </li>
                    <li className="me-2" role="presentation">
                      <ProfileTab
                        onClick={() => toTab(ActiveProfileTab.Details)}
                        icon={<UserIcon className="size-6" />}
                        title="Personal Details"
                        isActive={activeTab === ActiveProfileTab.Details}
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="w-full bg-grey rounded py-4 px-6">
                    <Outlet />
                  </div>
                </div>
              </div>
            </Section>
          </Content>
        </Page>
      </DesktopScreen>
      <MobileScreen>
        <div className="w-full mx-auto block md:hidden min-h-screen">
          {self && <UserInformation self={self} />}

          <ProfileTab
            onClick={() => toTab(ActiveProfileTab.Experiences)}
            title="My Experiences"
            icon={<TicketIcon />}
            isActive={activeTab === ActiveProfileTab.Experiences}
          />
          <Collapse open={activeTab === ActiveProfileTab.Experiences}>
            <div className="w-full bg-grey rounded py-4 px-6">
              <Outlet />
            </div>
          </Collapse>

          <ProfileTab
            onClick={() => toTab(ActiveProfileTab.Runs)}
            title="My Runs"
            icon={<TicketIcon />}
            isActive={activeTab === ActiveProfileTab.Runs}
          />
          <Collapse open={activeTab === ActiveProfileTab.Runs}>
            <div className="w-full bg-grey rounded py-4 px-6">
              <Outlet />
            </div>
          </Collapse>

          <ProfileTab
            onClick={() => toTab(ActiveProfileTab.Details)}
            title="Personal Details"
            icon={<UserIcon />}
            isActive={activeTab === ActiveProfileTab.Details}
          />
          <Collapse open={activeTab === ActiveProfileTab.Details}>
            <div className="w-full bg-grey rounded py-4 px-6">
              <Outlet />
            </div>
          </Collapse>
        </div>
      </MobileScreen>
    </>
  );
}
