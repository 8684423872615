import { createContext, useContext } from 'react';
import { UserProfile } from '~/generated/models/UserProfile';
import { DetailedExperience } from '~/generated/models/DetailedExperience';
import { User } from '~/generated/models/User';
import {
  CancelConnectionArgs,
  RespondConnectionArgs,
} from '~/generated/clients/private/connections/PrivateConnections.client';

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <UserContextProvider>.',
  );
};

export type UserContextType = {
  user: UserProfile | null;
  isSelf: boolean;
  fetchingUser: boolean;
  sharedExperiences: DetailedExperience[];
  fetchingSharedExperience: boolean;
  sharedRunClubs: DetailedExperience[];
  fetchingSharedRunClubs: boolean;
  sharedConnections: User[];
  fetchingSharedConnections: boolean;
  requestConnection: () => void;
  respondConnection: (args: RespondConnectionArgs) => void;
  cancelConnection: (args: CancelConnectionArgs) => void;
  error: Error | null;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  isSelf: false,
  fetchingUser: false,
  sharedExperiences: [],
  fetchingSharedExperience: false,
  sharedRunClubs: [],
  fetchingSharedRunClubs: false,
  sharedConnections: [],
  fetchingSharedConnections: false,
  requestConnection: stub,
  respondConnection: stub,
  cancelConnection: stub,
  error: null,
});

export function useUser() {
  return useContext(UserContext);
}
