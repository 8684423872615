import React, { useMemo } from 'react';
import { Button, Loader } from '@cg/module-frontend/src/components';
import { PlusIcon } from '@cg/module-frontend/src/icons';
import ExperienceCard from './ExperienceCard.tsx';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';
import { Host } from '~/generated/models/Host.ts';

type Props = {
  host?: Host; // pass this if all the experiences are from the same host
  experiences: DetailedExperience[];
  fetching: boolean;
  hasMore: boolean;
  fetchingMore: boolean;
  nextPage: () => void;
};

const ExperiencesStack = React.memo(
  ({ experiences, fetching, fetchingMore, hasMore, nextPage, host }: Props) => {
    const cards = useMemo(() => {
      return (
        experiences.map((experience) => (
          <ExperienceCard
            host={host ?? experience.host}
            experience={experience}
            key={experience.id.getValue()}
          />
        )) || []
      );
    }, [experiences, fetching]);

    if (experiences.length === 0) {
      return <div className="text-center py-20">Nothing yet.</div>;
    }

    return (
      <>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-4">
          <Loader className="w-full col-span-3" loading={fetching} horizontal />
          {cards}
        </div>

        {(hasMore || fetchingMore) && (
          <Button
            onClick={nextPage}
            color="secondary"
            className="w-full mt-4"
            loading={fetchingMore}
          >
            <div className="flex flex-row items-center justify-center">
              <PlusIcon className="me-2" />
              <span>Show More</span>
            </div>
          </Button>
        )}
      </>
    );
  },
);

ExperiencesStack.displayName = 'ExperiencesStack';

export default ExperiencesStack;
