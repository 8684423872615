import {
  calculateDays,
  calculateDistance,
  calculatePace,
  isRunClubVerified,
} from '@cg/module-frontend/src/hooks/series.hooks.tsx';
import { useMemo, useState } from 'react';
import { listSeriesHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks.ts';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries';
import { ExperienceType } from '~/generated/models/ExperienceType.ts';

export type ConsolidatedClub = {
  series: DetailedExperienceSeries;
  days: string;
  paces: string[];
  distances: number[];
};

/**
 * Hook to consolidate a list of series into clubs grouped by host.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to consolidate.
 * @returns {ConsolidatedClub[]} - An array of consolidated clubs.
 */
const useConsolidateClubs = (
  list: DetailedExperienceSeries[],
): ConsolidatedClub[] => {
  return useMemo(() => {
    const hosts: string[] = [];
    const todayIndex = new Date().getDay();
    const getNextDay = (days: string, currentDay: number): number => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 7; i++) {
        if (days[(currentDay + i) % 7] === '1') {
          return i;
        }
      }
      return Infinity;
    };

    return list
      .map((series) => {
        if (hosts.includes(series.host.id.getValue())) {
          return null;
        }

        const others = list.filter((s) => s.host.id.isEqual(series.host.id));
        hosts.push(series.host.id.getValue());
        const meta = others.map((s) => s?.meta?.experienceMeta ?? {});

        return {
          series,
          days: calculateDays(others.map((s) => s?.schedule ?? [])),
          paces: calculatePace(meta),
          distances: calculateDistance(meta),
        };
      })
      .filter(Boolean)
      .sort((a, b) => {
        // @ts-ignore
        const nextDayA = getNextDay(a.days, todayIndex);
        // @ts-ignore
        const nextDayB = getNextDay(b.days, todayIndex);

        return nextDayA - nextDayB;
      })
      .sort((a, b) => {
        if (isRunClubVerified(a?.series?.host)) {
          return -1;
        }
        if (isRunClubVerified(b?.series?.host)) {
          return 1;
        }
        return 0;
      }) as ConsolidatedClub[];
  }, [list]);
};

export type ListFilterDay = 'today' | 'tomorrow' | 'all';
type ListFilter = {
  day: ListFilterDay;
};

type Props = {
  pageSize?: number;
  initialFilters?: ListFilter;
};

export const useRunClubsList = (options?: Props) => {
  const pageSize = options?.pageSize ?? 6;
  const [filters, setFilters] = useState<ListFilter>({
    day: 'today',
    ...options?.initialFilters,
  });

  const [count, setCount] = useState(pageSize);
  const { data, calling } = listSeriesHook(true, {
    filters: {
      experienceType: ExperienceType.RunClub,
      pageSize: 100,
    },
  });
  const nextPage = () => {
    setCount(count + pageSize);
  };

  const runs = data?.result ?? [];
  const clubs = useConsolidateClubs(runs);
  const todayIndex = new Date().getDay();
  const tomorrowIndex = (todayIndex + 1) % 7;
  const filteredClubs = useMemo(() => {
    if (filters.day === 'today') {
      return clubs.filter((club) => club.days[todayIndex] === '1');
    }
    if (filters.day === 'tomorrow') {
      return clubs.filter((club) => club.days[tomorrowIndex] === '1');
    }
    return clubs; // 'all'
  }, [filters.day, clubs]);

  return {
    clubs: filteredClubs.slice(0, count),
    calling,
    hasMore: count < filteredClubs.length,
    nextPage,
    filters,
    setFilters,
    showAll: () => setCount(filteredClubs.length),
  };
};
