import React, { memo, useMemo } from 'react';
import {
  CalendarIcon,
  LocationIcon,
  TimerIcon,
  DistanceIcon,
} from '@cg/module-frontend/src/icons';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Img, Tag } from '@cg/module-frontend/src/components';
import {
  useDistanceCalculator,
  useDistanceRangeDom,
  usePaceCalculator,
  usePaceRangeDom,
} from '@cg/module-frontend/src/hooks/series.hooks';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries';

import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';
import { useHostPage } from '~/pages/hosts/host/context';
import { FamiliarFacesSummary } from '../familiar';

type Props = {
  experiences: DetailedExperience[];
  series: DetailedExperienceSeries;
  action: React.ReactNode;
};

const RunSummaryCard = React.memo(({ series, experiences, action }: Props) => {
  const { attendingExperiences } = useHostPage();
  const meta = series.meta?.experienceMeta ?? {};
  const paces = usePaceCalculator([meta]);
  const distances = useDistanceCalculator([meta]);
  const formattedDate = useMemo(() => {
    const startDate = DateUtils.dater(experiences[0].startDate);
    return `${startDate.format('dddd')}s @ ${startDate.format('h:mmA')}`;
  }, [experiences]);

  const paceRange = usePaceRangeDom(paces);
  const distanceRange = useDistanceRangeDom(distances);

  const familiarFaces = experiences.flatMap((e) => e.connections);
  const isAttending = attendingExperiences.some((e) =>
    e.experienceSeriesId?.isEqual(series.id),
  );

  const getAddress = () => {
    if (
      series.address.name &&
      series.address.name !== series.address.neighbourhood
    ) {
      return `${series.address.name}, ${series.address.neighbourhood}`;
    }

    return (
      series.address.name || series.address.neighbourhood || series.address.city
    );
  };

  return (
    <div className="bg-white rounded pt-4 w-full h-fit flex flex-col">
      <span className="px-4">
        <div className="w-full relative flex flex-row grow justify-between">
          <div className="w-full grow flex flex-col">
            <h4>{series.title}</h4>
            <div className="py-3 flex flex-col space-y-2">
              <div className="flex items-center space-x-1">
                <CalendarIcon className="text-primary stroke-primary" />
                <span className="text-sm font-normal">{formattedDate}</span>
              </div>
              <div className="flex items-center space-x-1">
                <LocationIcon className="text-primary stroke-primary" />
                <span className="text-sm">{getAddress()}</span>
              </div>
              <div className="flex items-center space-x-1">
                <TimerIcon className="text-primary stroke-primary" />
                {paceRange}
              </div>
              <div className="flex items-center space-x-1">
                <DistanceIcon className="text-primary stroke-primary" />
                {distanceRange}
              </div>
            </div>
          </div>
          <div className="w-[10rem] h-full flex aspect-square rounded relative justify-center items-center">
            <div className="rounded relative overflow-hidden w-full">
              {isAttending && <Tag position="bottomCenter" text="Attending" />}
              <Img
                alt={series.title}
                src={series.bannerUrl}
                className="aspect-square rounded object-cover"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-2">{experiences[0].summary}</div>
        {action}
      </span>
      <div className="w-full flex items-center justify-center mt-4 border-t px-2">
        <FamiliarFacesSummary users={familiarFaces ?? []} />
      </div>
    </div>
  );
});

RunSummaryCard.displayName = 'RunSummaryCard';

export default memo(RunSummaryCard);
