import { Dropdown, TextInput } from 'flowbite-react';
import CountryList from 'country-list-with-dial-code-and-flag';

import React, { forwardRef, useState } from 'react';
import 'react-phone-input-2/lib/style.css';

type Props = {
  value?: string;
  onChange?: (event: string) => void;
  onBlur?: (event: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

const priorityCountries = ['CA', 'US', 'MX'];

const allCountries = CountryList.getAll({ withSecondary: false });

const PhoneNumber = forwardRef<HTMLInputElement, Props>(
  ({ onChange, value, disabled, placeholder, onBlur }, ref) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState(''); // Displayed value without the area code

    const countries = [...allCountries]
      .sort((a, b) => {
        const isAPriority = priorityCountries.includes(a.code);
        const isBPriority = priorityCountries.includes(b.code);

        if (isAPriority && !isBPriority) {
          return -1;
        }
        if (!isAPriority && isBPriority) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
      .filter((country) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );

    const [selectedCountry, setSelectedCountry] = useState(() => {
      // Helper function to find the correct country
      const findCountryByPhoneNumber = (phone: string | undefined) => {
        if (!phone) {
          return CountryList.findOneByCountryCode('CA');
        } // Default to Canada
        return (
          CountryList.getAll({ withSecondary: false }).find((country) =>
            phone.startsWith(country.countryCode),
          ) || CountryList.findOneByCountryCode('CA') // Default if no match
        );
      };

      return findCountryByPhoneNumber(value);
    });

    // Sync displayed input value when `value` changes
    React.useEffect(() => {
      if (value) {
        const code = selectedCountry?.countryCode || '+1';
        if (value.startsWith(code)) {
          setInputValue(value.replace(code, '').trim());
        } else {
          setInputValue(value);
        }
      }
    }, [value, selectedCountry]);

    const handleCountryChange = (country: (typeof countries)[number]) => {
      const oldCode = selectedCountry?.countryCode ?? '+1';
      setSelectedCountry(country);

      if (onChange && value) {
        const newPhoneValue = country.countryCode + value.replace(oldCode, '');
        onChange(newPhoneValue);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newInputValue = e.target.value;
      setInputValue(newInputValue);

      if (onChange) {
        const code = selectedCountry?.countryCode ?? '+1';
        const phoneValue = code + newInputValue.replace(code, '');
        onChange(phoneValue);
      }
    };

    return (
      <TextInput
        disabled={disabled}
        addon={
          <div className="px-2">
            <Dropdown label={selectedCountry?.flag} inline disabled={disabled}>
              <div className="max-h-60 overflow-y-auto">
                <Dropdown.Item className="p-2">
                  <TextInput
                    style={{ height: '30px' }}
                    placeholder="Search countries..."
                    value={searchTerm}
                    onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to dropdown
                    onFocus={(e) => e.stopPropagation()} // Prevent focus event from closing the dropdown
                    onKeyDown={(e) => {
                      if (e.key.length === 1 || e.key === 'Backspace') {
                        setSearchTerm(
                          (prev) =>
                            e.key === 'Backspace'
                              ? prev.slice(0, -1) // Handle backspace
                              : prev + e.key, // Handle regular key press
                        );
                      }
                    }}
                    className="w-full"
                  />
                </Dropdown.Item>
                <Dropdown.Divider />
                {/* Render Countries */}
                {countries.map((country) => (
                  <Dropdown.Item
                    key={country.code}
                    onClick={() => handleCountryChange(country)}
                  >
                    {country.flag} {country.name}
                    <span className="text-gray-500 ml-2">
                      {country.countryCode}
                    </span>
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown>
          </div>
        }
        type="number"
        inputMode="numeric"
        placeholder={placeholder}
        value={inputValue}
        ref={ref}
        // @ts-ignore
        onBlur={onBlur}
        onChange={handleInputChange}
        className="flex-1 w-full"
      />
    );
  },
);

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;
