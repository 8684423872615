import { useScreenSize } from '@cg/module-frontend/src/hooks';

import classNames from 'classnames';
import React, { ReactNode } from 'react';

type UserProfileTabProps = {
  count: number;
  title: ReactNode;
  isActive: boolean;
};

function UserProfileTab({ count, title, isActive }: UserProfileTabProps) {
  const { isSmall } = useScreenSize();
  return (
    <button
      type="button"
      className={classNames(
        'px-4 py-3 flex justify-between items-center w-full border-b-2',
        {
          'md:border-b-primary': isActive,
          'bg-grey md:border-primary md:border': isActive && isSmall,
          'md:rounded rounded-t px-2': isSmall,
        },
      )}
    >
      <div
        className={classNames(
          'transition-all flex flex-row space-x-1 items-center justify-center space-x-2',
          {
            'text-primary': isActive,
          },
        )}
      >
        <span className="font-bold text-xl">{count}</span>
        <span
          className={classNames('font-bold text-sm', {
            'text-primary': isActive,
          })}
        >
          {title}
        </span>
      </div>
    </button>
  );
}

export default UserProfileTab;
