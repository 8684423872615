import {
  Autoplay,
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper/modules';
import { Swiper, SwiperRef } from 'swiper/react';
import React, { forwardRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { PaginationOptions, SwiperOptions } from 'swiper/types';
import classNames from 'classnames';
import { useScreenSize } from '../../hooks';

type Props = {
  className?: string;
  onReachEnd?: () => void;
  usePagination?: boolean | 'corner-right';
  useFreeMode?: boolean;
  useNavigation?: boolean;
  useAutoPlay?: boolean;
  containerClassName?: string;
  children: React.ReactNode;
  breakpoints?: {
    [width: number]: SwiperOptions;
  };
};

const Swipper = forwardRef<SwiperRef, Props>(
  (
    {
      className,
      onReachEnd,
      children,
      usePagination,
      useFreeMode,
      useNavigation,
      containerClassName,
      useAutoPlay,
      breakpoints,
    }: Props,
    ref,
  ) => {
    const [isReady, setIsReady] = React.useState(false);
    const hasChildren = React.Children.count(children) > 0;
    breakpoints = breakpoints ?? {
      640: {
        spaceBetween: 20,
      },
      768: {
        spaceBetween: 40,
      },
      1024: {
        spaceBetween: 24,
      },
    };

    React.useEffect(() => {
      if (hasChildren && !isReady) {
        setIsReady(true);
      }
    }, [hasChildren, isReady]);

    const { isMobile } = useScreenSize();
    const pagination: PaginationOptions | boolean = React.useMemo(
      () =>
        usePagination
          ? {
              el: '.pagination-container',
              clickable: true,
              type: 'bullets',
              modifierClass: '',
              renderBullet: (_, cName: string) => {
                return `<span class="${cName} inline-block mx-1 w-2 rounded bg-primary"></span>`;
              },
            }
          : false,
      [usePagination],
    );
    const modules = React.useMemo(() => {
      const baseModules = [Mousewheel, Navigation, FreeMode];
      if (usePagination) {
        baseModules.push(Pagination);
      }
      if (useAutoPlay) {
        baseModules.push(Autoplay);
      }
      return baseModules;
    }, [usePagination, useAutoPlay]);

    const freeMode = React.useMemo(
      () => (useFreeMode ? { enabled: true, sticky: false } : false),
      [useFreeMode],
    );

    const navigation = React.useMemo(
      () =>
        useNavigation
          ? {
              enabled: true,
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          : false,
      [useNavigation],
    );

    if (!hasChildren || !isReady) {
      return null;
    }

    return (
      <div className={classNames('relative', containerClassName)}>
        {usePagination && (
          <div
            className={classNames(
              'pagination-container absolute bottom-2 h-fit w-fit rounded bg-grey text-center px-2 z-40',
              {
                'left-1/2 transform -translate-x-1/2': usePagination === true,
                'right-2': usePagination === 'corner-right',
              },
            )}
          />
        )}
        <Swiper
          ref={ref}
          enabled
          initialSlide={0}
          observer
          observeParents
          style={{
            // @ts-ignore
            '--swiper-navigation-size': '25px',
            // @ts-ignore
            '--swiper-navigation-sides-offset': '0px',
          }}
          modules={modules}
          className={className}
          slidesPerView="auto"
          onReachEnd={onReachEnd}
          navigation={navigation}
          spaceBetween={20}
          mousewheel={{
            sensitivity: 4,
          }}
          speed={500}
          effect="slide"
          freeMode={freeMode}
          direction="horizontal"
          simulateTouch={isMobile}
          keyboard={{ enabled: true }}
          scrollbar={{ draggable: true, enabled: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={useAutoPlay}
          pagination={pagination}
          breakpoints={breakpoints}
        >
          {children}
          {useNavigation && (
            <>
              <div className="swiper-button-next !text-primary !after:text-sm" />
              <div className="swiper-button-prev !text-primary" />
            </>
          )}
        </Swiper>
      </div>
    );
  },
);

export default Swipper;
