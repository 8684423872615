import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Href } from '@cg/module-frontend/src/components';
import { isRunClubVerified } from '@cg/module-frontend/src/hooks/series.hooks';
import { RunSummaryCard } from '~/components';
import { Host } from '~/generated/models/Host.ts';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';
import SignupRunModal from './Signup';

type Props = {
  host: Host;
  experiences: DetailedExperience[];
  series: DetailedExperienceSeries;
};

function UpcomingRunCard({ host, experiences, series }: Props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const isAutoRegister =
    searchParams.get('autoRegister') === 'true' &&
    searchParams.get('seriesId') === series.id.getValue();
  const idsArray = searchParams.get('experienceIds')?.split(',') ?? [];
  const initialState = experiences.filter((e) =>
    idsArray.includes(e.id.getValue()),
  );
  const allowSignup = isRunClubVerified(host);
  useEffect(() => {
    if (isAutoRegister) {
      setShowModal(true);
    }
  }, [isAutoRegister]);

  return (
    <>
      <SignupRunModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          navigate(window.location.pathname, { replace: true });
        }}
        initialPage={isAutoRegister ? 'auto-register' : 'selection'}
        initialState={initialState}
        series={series}
        experiences={experiences}
      />
      <RunSummaryCard
        series={series}
        experiences={experiences}
        action={
          <>
            <Button
              disabled={!allowSignup}
              className="w-full"
              toolTip={
                host.hasUsers
                  ? ''
                  : "Sign-ups aren't enabled for this Club yet."
              }
              color="primary"
              onClick={() => setShowModal(true)}
            >
              <div className="w-full">Signup</div>
            </Button>
            {series.host.socials?.instagram && !allowSignup && (
              <Href
                target="_blank"
                to={series.host.socials?.instagram}
                className="block text-center mt-2"
                track={{
                  $$type: 'visitHostInstagram',
                  $$host: series.host.friendlyName,
                  $$hostId: series.host.id.getValue(),
                }}
              >
                Go to Instagram
              </Href>
            )}
          </>
        }
      />
    </>
  );
}

UpcomingRunCard.displayName = 'UpcomingRunCard';

export default UpcomingRunCard;
