import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ExperienceId } from '@cg/common/src/ids';
import { useLogger } from '@cg/module-frontend';
import { HostPageContext, HostPageContextType } from './HostPageContext.tsx';
import {
  listExperiencesHook,
  listHostUsersHook,
  listSeriesHook,
} from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { ExperienceType } from '~/generated/models/ExperienceType';
import { useHostLayout } from '~/app/context/host';
import { TicketTier } from '~/generated/models/TicketTier.ts';
import {
  checkout,
  listTicketTiers,
} from '~/generated/clients/public/experiences/PublicExperiences.client.ts';
import { UserCheckoutRequest } from '~/generated/models/UserCheckoutRequest.ts';
import { listAttendingExperiencesHook } from '~/generated/clients/private/experiences/PrivateExperiences.hooks.ts';
import { TicketStatus } from '~/generated/models/TicketStatus.ts';

type Prop = {
  children: ReactNode;
};

export function HostPageContextProvider({ children }: Prop) {
  const { host } = useHostLayout();
  const logger = useLogger('HostPageContextProvider');
  const { data: attendingExperiences } = listAttendingExperiencesHook(true, {
    filters: {
      ticketStatus: [
        TicketStatus.NoShow,
        TicketStatus.Confirmed,
        TicketStatus.Reselling,
        TicketStatus.Refunded,
      ],
    },
  });

  const {
    data: hostUsers,
    calling: fetchingHostUser,
    call: fetchHostUsers,
  } = listHostUsersHook(false);

  const {
    data: experiences,
    calling: fetchingExperiences,
    call: fetchExperiences,
  } = listExperiencesHook(false);
  const {
    data: seriesExperiences,
    calling: fetchingSeriesExperiences,
    call: fetchSeriesExperiences,
  } = listExperiencesHook(false);
  const {
    data: series,
    calling: fetchingSeries,
    call: fetchSeries,
  } = listSeriesHook(false);

  useEffect(() => {
    if (!host) {
      return;
    }

    fetchHostUsers({ ids: { hostId: host.id } });
    fetchExperiences({
      ids: { hostId: host.id },
      filters: {
        experienceType: ExperienceType.OneTime,
      },
    });
    fetchSeriesExperiences({
      ids: { hostId: host.id },
      filters: {
        experienceType: ExperienceType.RunClub,
      },
    });
    fetchSeries({ ids: { hostId: host.id } });
  }, [host]);

  const [ticketTiers, setTicketTiers] = useState<TicketTier[]>([]);
  const ticketTiersRef = useRef<TicketTier[]>([]);
  useEffect(() => {
    ticketTiersRef.current = ticketTiers;
  }, [ticketTiers]);
  const inflightRequests = useRef<Set<Promise<void>>>(new Set());
  const fetchTicketTiers = async (experienceId: ExperienceId) => {
    // we've already fetched this
    if (ticketTiers.some((t) => t.experienceId.isEqual(experienceId))) {
      return;
    }

    const request = (async () => {
      const response = await listTicketTiers({
        ids: { experienceId },
      });

      if (response.succeeded) {
        setTicketTiers((prev) => [...prev, ...response.payload.result]);
      }
    })();

    inflightRequests.current.add(request);
    try {
      await request;
    } finally {
      inflightRequests.current.delete(request);
    }
  };

  const [registering, setRegistering] = useState(false);
  const registerForRun = async (
    user: UserCheckoutRequest,
    experienceIds: ExperienceId[],
  ) => {
    setRegistering(true);
    if (inflightRequests.current.size > 0) {
      await Promise.all(Array.from(inflightRequests.current));
    }
    setTimeout(async () => {
      const requests = experienceIds.map((id) => {
        const tier = ticketTiersRef.current.find((t) =>
          t.experienceId.isEqual(id),
        );
        if (!tier) {
          // TODO: FIX
          logger.error('No ticket tier found for experience %s', id.getValue());
          throw new Error('No ticket tier found for experience');
        }
        if (tier.price !== 0) {
          // TODO: FIX
          logger.error(
            'Only free experiences are supported and experience %s is not free',
            id.getValue(),
          );
          throw new Error('Only free experiences are supported');
        }

        return {
          experienceId: id,
          ticketTierId: tier.id,
        };
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const r of requests) {
        const response = await checkout({
          ids: { experienceId: r.experienceId },
          body: [
            {
              ticketTierId: r.ticketTierId,
              user,
              answers: [],
            },
          ],
        });
        if (!response.succeeded) {
          logger.error(
            'User %s failed to register for experience %s because %s',
            user.email,
            r.experienceId.getValue(),
            response.payload,
          );
        }
      }
      setRegistering(false);
    });
  };

  // Create context to send over
  const context = useMemo<HostPageContextType>(
    () => ({
      hostUsers: hostUsers?.result || [],
      fetchingSeries: fetchingSeriesExperiences || fetchingSeries,
      series: series?.result || [],
      seriesExperiences: seriesExperiences?.result || [],
      fetchingExperiences,
      experiences: experiences?.result || [],
      attendingExperiences: attendingExperiences?.result || [],
      registerForRun,
      fetchTicketTiers,
      ticketTiers,
      registering,
    }),
    [
      fetchingHostUser,
      fetchingExperiences,
      fetchingSeries,
      fetchingSeriesExperiences,
      series,
      seriesExperiences,
      experiences,
      attendingExperiences,
      ticketTiers,
      registering,
    ],
  );

  return (
    <HostPageContext.Provider value={context}>
      {children}
    </HostPageContext.Provider>
  );
}
