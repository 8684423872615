import { featureFlagConstants } from '@cg/common/src/constants';
import { Host } from '~/generated/models/Host';
import { HostUserProfile } from '~/generated/models/HostUserProfile.ts';
import HostUsers from './HostUsers';

import Img from '../img';
import { useHostSocials } from './hooks';
import { useAuth } from '../../core/auth';
import { Link } from '../links';
import { Button } from '../buttons';

type Props = {
  host: Host;
  users: HostUserProfile[];
  className?: string;
};

export default function HostDetail({ host, className, users }: Props) {
  users = users.filter((u) => u.imageUrl);
  const { hasFlag } = useAuth();
  const showVisitHost = hasFlag(featureFlagConstants.COMMONGROUND_SERIES);
  const socials = useHostSocials(host);

  return (
    <div className={className}>
      <div className="bg-grey rounded p-4 ">
        <div className="flex items-center gap-4">
          {host.imageUrl && (
            <Img
              src={host.imageUrl}
              alt={host.friendlyName}
              className="size-16 object-contain rounded-full"
            />
          )}

          <div className="w-full space-y-1">
            <div className="flex justify-between items-center">
              <h3 className="font-medium">Your Host:</h3>
              <div className="flex space-x-2">{socials}</div>
            </div>
            <h4>{host.friendlyName}</h4>
          </div>
        </div>

        <HostUsers users={users} className="mt-4" />
        <div>
          <p className="text-black font-light mt-4">{host.about}</p>
        </div>
        {showVisitHost && (
          <Link to={`/h/${host.uniqueName}`} className="flex w-full mt-4">
            <Button color="secondary" className="w-full">
              Meet Your Host
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}
