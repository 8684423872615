import React from 'react';
import classNames from 'classnames';
import {
  ListFilterDay,
  useRunClubsList,
} from '~/components/clubs/runs.hooks.ts';
import { RunClubsStack } from '~/components';
import ListsController from './ListController';

const RunClubsList = React.memo(() => {
  const { clubs, calling, hasMore, showAll, filters, setFilters } =
    useRunClubsList();

  const selectionButton = (value: ListFilterDay) => {
    const isActive = value === filters.day;
    return (
      <button
        type="button"
        className={classNames('rounded-lg p-2 cursor-pointer', {
          'bg-primary text-white': isActive,
          'bg-white text-black hover:bg-secondary': !isActive,
        })}
        onClick={() => {
          setFilters({ ...filters, day: value });
        }}
      >
        {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
      </button>
    );
  };

  return (
    <ListsController
      title="Run Clubs"
      stack={
        <>
          <div className="w-full bg-grey-darker rounded grid grid-cols-3 gap-4 p-2 mb-4 font-semibold">
            {selectionButton('today')}
            {selectionButton('tomorrow')}
            {selectionButton('all')}
          </div>
          <RunClubsStack
            clubs={clubs}
            fetching={calling}
            hasMore={hasMore}
            showAll={showAll}
          />
        </>
      }
      topOffset={72}
    />
  );
});

export default RunClubsList;
