import React, { useMemo } from 'react';
import { Loader, SwiperSlide } from '@cg/module-frontend/src/components';
import { Carrousel } from '../common';
import RunClubCard from './RunClubCard';
import { useRunClubsList } from './runs.hooks.ts';

type Props = {
  showAll?: boolean;
};

const RunClubsCarrousel = React.memo(({ showAll }: Props) => {
  const { clubs, calling, hasMore, nextPage } = useRunClubsList({
    initialFilters: { day: showAll ? 'all' : 'today' },
  });

  const cards = useMemo(() => {
    const slides = clubs.map(({ series, days, paces, distances }) => (
      <SwiperSlide className="!overflow-hidden" key={series.id.getValue()}>
        <RunClubCard
          series={series}
          days={days}
          paces={[paces[0], paces[paces.length - 1]]}
          distances={[distances[0], distances[distances.length - 1]]}
        />
      </SwiperSlide>
    ));

    if (calling) {
      slides.push(
        <SwiperSlide className="!overflow-hidden !w-60 !h-[50vh]" key="loader">
          <Loader loading vertical />
        </SwiperSlide>,
      );
    }

    return slides;
  }, [clubs, calling]);

  return (
    <Carrousel title="Run Clubs" hasMore={hasMore} fetchMore={nextPage}>
      {cards}
    </Carrousel>
  );
});

RunClubsCarrousel.displayName = 'RunClubsCarrousel';

export default RunClubsCarrousel;
