/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { listMutualConnections, ListMutualConnectionsArgs, listMutualExperiences, ListMutualExperiencesArgs } from './PrivateUsers.client';

export const listMutualConnectionsHook = (immediateCall: boolean = true, initArgs?: ListMutualConnectionsArgs) => {
  return useHttp(listMutualConnections, { immediateCall, initArgs })
};
export const listMutualExperiencesHook = (immediateCall: boolean = true, initArgs?: ListMutualExperiencesArgs) => {
  return useHttp(listMutualExperiences, { immediateCall, initArgs })
};
