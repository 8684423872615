import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import { BackToTopIcon } from '@cg/module-frontend/src/icons';

type Props = {
  title: string;
  stack: React.ReactNode;
  topOffset: number;
};

function ListsController({ title, stack, topOffset }: Props) {
  const { isSmall } = useScreenSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const scrollToTop = () => {
    if (containerRef.current) {
      const topPosition =
        containerRef.current.getBoundingClientRect().top +
        window.scrollY -
        topOffset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { top, bottom } = containerRef.current.getBoundingClientRect();
        const isWithinView = top <= 64 && bottom >= 0;
        setIsSticky(isWithinView);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="w-full h-fit bg-grey rounded mt-10" ref={containerRef}>
      <div
        className={classNames('transition-all duration-100', {
          'sticky top-16 z-30': isSmall,
          'bg-white shadow-md': isSmall && isSticky,
          'bg-grey rounded': !(isSmall && isSticky),
        })}
      >
        <div
          className={classNames({
            'flex justify-between items-center py-4': true,
            'container mx-auto px-4': isSmall && isSticky,
            'px-4': !(isSmall && isSticky),
          })}
        >
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
            <h3 className="font-normal text-black-lighter">{title}</h3>
          </div>
          {isSmall && isSticky && (
            <div>
              <BackToTopIcon
                className="text-primary size-6"
                onClick={scrollToTop}
              />
            </div>
          )}
        </div>
      </div>

      <div className={classNames('bg-grey rounded px-4 pb-4')}>{stack}</div>
    </div>
  );
}

ListsController.displayName = 'ListsController';

export default ListsController;
