/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { cancelConnection, CancelConnectionArgs, listConnections, requestConnection, RequestConnectionArgs, respondConnection, RespondConnectionArgs } from './PrivateConnections.client';

export const cancelConnectionHook = (immediateCall: boolean = true, initArgs?: CancelConnectionArgs) => {
  return useHttp(cancelConnection, { immediateCall, initArgs })
};
export const listConnectionsHook = (immediateCall: boolean = true, ) => {
  return useHttp(listConnections, { immediateCall })
};
export const requestConnectionHook = (immediateCall: boolean = true, initArgs?: RequestConnectionArgs) => {
  return useHttp(requestConnection, { immediateCall, initArgs })
};
export const respondConnectionHook = (immediateCall: boolean = true, initArgs?: RespondConnectionArgs) => {
  return useHttp(respondConnection, { immediateCall, initArgs })
};
