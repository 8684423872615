import { Loader } from '@cg/module-frontend/src/components';
import { useHostLayout } from '~/app/context/host';
import HostPage from './HostPage';
import { HostPageContextProvider } from '~/pages/hosts/host/context';

export default function Host() {
  const { host, fetchingHost } = useHostLayout();

  return (
    <HostPageContextProvider>
      <Loader loading={fetchingHost}>{host && <HostPage host={host} />}</Loader>
    </HostPageContextProvider>
  );
}
