import { HostUsers, Loader } from '@cg/module-frontend/src/components';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { listFamiliarFacesByHostHook } from '~/generated/clients/private/hosts/PrivateHosts.hooks';
import FamiliarFaces from './components/FamiliarFaces';
import { Host } from '~/generated/models/Host';
import UpcomingRunCard from './components/UpcomingRunCard';
import { ExperienceSummaryCard, RunClubsCarrousel } from '~/components';
import { useHostPage } from '~/pages/hosts/host/context';

type Props = {
  host: Host;
};

export default function HostPage({ host }: Props) {
  const {
    hostUsers,
    experiences,
    fetchingExperiences,
    seriesExperiences,
    series,
    fetchingSeries,
  } = useHostPage();
  const { isSmall } = useScreenSize();

  const { data: mutualUsers, calling: fetchingMutualUsers } =
    listFamiliarFacesByHostHook(true, { ids: { hostId: host.id } });

  const upcomingExperiencesList = useMemo(() => {
    return experiences.slice(0, 5).map((experience) => {
      return (
        <ExperienceSummaryCard
          hideHost
          experience={experience}
          host={host}
          key={experience.id.getValue()}
        />
      );
    });
  }, [experiences, host]);

  const upcomingSeriesList = useMemo(() => {
    return series.slice(0, 5).map((item) => {
      const filteredExperiences = seriesExperiences.filter((s) =>
        s?.experienceSeriesId?.isEqual(item.id),
      );
      if (filteredExperiences.length === 0) {
        return null;
      }

      return (
        <UpcomingRunCard
          host={host}
          experiences={filteredExperiences}
          series={item}
          key={item.id.getValue()}
        />
      );
    });
  }, [series, seriesExperiences]);

  return (
    <>
      <div
        className={`relative flex gap-4 ${isSmall ? 'flex-col' : 'flex-row'}`}
      >
        <div
          className={classNames(
            'h-full bg-grey p-2 rounded flex flex-col gap-y-4 px-4',
            {
              'w-7/12': !isSmall,
              'w-full': isSmall,
            },
          )}
        >
          <div className="rounded flex flex-col gap-y-2">
            <h3>Summary</h3>
            <span>{host.about ?? host.summary}</span>
          </div>

          <Loader loading={fetchingExperiences}>
            {experiences.length > 0 && (
              <>
                <div className="w-full my-1 flex flex-row justify-between items-center">
                  <h3>Upcoming Experiences</h3>
                </div>
                <div className="relative flex flex-col gap-y-2 gap-x-4">
                  {upcomingExperiencesList}
                </div>
              </>
            )}
          </Loader>

          <Loader loading={fetchingSeries}>
            {series.length > 0 && seriesExperiences.length > 0 && (
              <>
                <div className="w-full mt-4 my-1 flex flex-row justify-between items-center">
                  <h3>Upcoming Runs</h3>
                </div>
                <div className="relative flex flex-col gap-y-6 gap-x-4">
                  {upcomingSeriesList}
                </div>
              </>
            )}
          </Loader>

          {hostUsers.length > 0 && (
            <>
              <div className="relative flex mt-4">
                <h3>Meet Your Hosts</h3>
              </div>
              <div className="relative flex mb-4">
                <HostUsers users={hostUsers} />
              </div>
            </>
          )}
        </div>
        <div
          className={classNames('h-full rounded flex flex-col gap-y-4', {
            'w-full': isSmall,
            'w-5/12': !isSmall,
          })}
        >
          <Loader loading={fetchingMutualUsers}>
            <FamiliarFaces users={mutualUsers || []} />
          </Loader>
        </div>
      </div>
      {series.length > 0 && <RunClubsCarrousel showAll />}
    </>
  );
}
