import React, { useState } from 'react';
import { Button, Loader, UserPill } from '@cg/module-frontend/src/components';
import { Link } from 'react-router-dom';
import { dater } from '@cg/common/src/utils';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@cg/module-frontend/src/icons';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';
import { listBatchExperiencesHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import { DetailedExperience } from '~/generated/models/DetailedExperience';

type FamiliarFaceItemProps = {
  user: UserWithSharedExperience;
};

const minItems = 5;

export default function FamiliarFaceItem({ user }: FamiliarFaceItemProps) {
  const [isOpen, setIsOpen] = useState(true);

  const {
    data: experiences,
    call: fetchSharedExperiences,
    calling: fetchingSharedExperiences,
  } = listBatchExperiencesHook(false);

  const fetchArg = {
    body: {
      experienceIds: user.experienceIds,
    },
  };

  const experienceCount = experiences?.length || 0;
  const itemsToDisplay = isOpen ? minItems : Math.max(20, experienceCount);
  const hasMore = Math.max(0, experienceCount - minItems);

  return (
    <UserPill
      user={user}
      collapsable
      onToggle={() => fetchSharedExperiences(fetchArg)}
      openChildren={
        <div className="mt-2 space-y-3 overflow-y-scroll max-h-[60vh]">
          <Loader
            horizontal
            loading={fetchingSharedExperiences}
            className="min-h-4"
          >
            <>
              {(experiences || [])
                .slice(0, itemsToDisplay)
                .map((experience: DetailedExperience) => (
                  <div
                    key={experience.id.getValue()}
                    className="flex justify-between p-2 items-center bg-white rounded hover:underline"
                  >
                    <Link
                      to={`/e/${experience.host.uniqueName}--${experience.uniqueName}`}
                      className="flex items-center justify-between w-full"
                    >
                      <div className="flex space-x-2 items-center flex-1 min-w-0">
                        <span className="text-base font-medium truncate">
                          {experience.title}
                        </span>
                      </div>
                      <div className="flex flex-row space-x-2 ml-2">
                        <CalendarIcon className="text-primary stroke-primary size-6" />
                        <span className="text-primary text-sm text-left">
                          {dater(experience.startDate).format('MMM Do, YYYY')}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
              {isOpen && hasMore ? (
                <Button
                  color="secondary"
                  className="w-full"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span>+{hasMore} more</span>
                  <ChevronRightIcon className="text-primary stroke-primary size-6" />
                </Button>
              ) : (
                minItems < experienceCount && (
                  <Button
                    color="secondary"
                    className="w-full items-center justify-center hover:bg-white"
                    size="sm"
                    onClick={() => setIsOpen(true)}
                  >
                    <span>Collapse</span>
                    <ChevronDownIcon className="text-primary stroke-primary size-6" />
                  </Button>
                )
              )}
            </>
          </Loader>
        </div>
      }
    >
      <span className="text-sm font-light truncate ml-auto">
        <b className="font-bold">{user.shared}</b> shared Experiences
      </span>
    </UserPill>
  );
}
