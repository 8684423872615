import { Swipper } from '@cg/module-frontend/src/components';
import React from 'react';

type Props = {
  title: string;
  hideCity?: boolean;
  hasMore: boolean;
  fetchMore: () => void;
  children: React.ReactNode;
};

function Carrousel({ title, hideCity, fetchMore, hasMore, children }: Props) {
  return (
    <div className="w-full h-fit bg-grey rounded mt-10 py-4 px-4">
      <span className="flex justify-between items-center mb-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center">
          <h3 className="font-normal text-black-lighter">{title}</h3>
          {!hideCity && (
            <h4 className="text-primary font-normal sm:ml-2">
              in <strong>Vancouver</strong>
            </h4>
          )}
        </div>
      </span>

      <Swipper
        useFreeMode
        useNavigation
        onReachEnd={() => {
          if (hasMore) {
            fetchMore();
          }
        }}
        breakpoints={{
          640: {
            spaceBetween: 20,
            slidesPerView: 1,
          },
          768: {
            spaceBetween: 40,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 24,
            slidesPerView: 3,
          },
        }}
      >
        {children}
      </Swipper>
    </div>
  );
}

Carrousel.displayName = 'Carrousel';

export default Carrousel;
