import React from 'react';
import classNames from 'classnames';

type Props = {
  text: string;
  position?:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'bottomCenter';
  className?: string;
};

function getPositionClass(position?: Props['position']) {
  switch (position) {
    case 'topLeft':
      return 'top-2 left-2';
    case 'topRight':
      return 'top-2 right-2';
    case 'bottomLeft':
      return 'bottom-2 left-2';
    case 'bottomRight':
      return 'bottom-2 right-2';
    case 'bottomCenter':
      return 'bottom-2 left-1/2 transform -translate-x-1/2';
    default:
      return '';
  }
}

function Tag({ text, className, position }: Props) {
  return (
    <span
      className={classNames(
        className,
        getPositionClass(position),
        'bg-secondary rounded text-xs font-semibold p-1 px-2 text-primary absolute',
      )}
    >
      {text}
    </span>
  );
}

Tag.displayName = 'Tag';

export default Tag;
