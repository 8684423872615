import React from 'react';
import {
  Button,
  DesktopScreen,
  Img,
  Markdown,
  MobileScreen,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import { ExternalIcon } from '@cg/module-frontend/src/icons';
import { Experience } from '~/generated/models/Experience';
import { Host } from '~/generated/models/Host';
import { ExperienceConfirmation } from '~/generated/models/ExperienceConfirmation';

type MessageFromHostProps = {
  confirmation: ExperienceConfirmation | null;
  experience: Experience | null;
  host: Host | null;
  hideCTA: boolean;
};

function MessageFromHost({
  experience,
  confirmation,
  hideCTA,
  host,
}: MessageFromHostProps) {
  const showConfirmation = confirmation?.text || false;

  const cta = () => {
    if (confirmation?.cta?.url && confirmation?.cta?.text && !hideCTA) {
      let url = confirmation?.cta?.url;
      if (!/^https?:\/\//i.test(url)) {
        // If not, prepend 'https://'
        url = `https://${url}`;
      }

      return (
        <Button
          color="primary"
          onClick={() => {
            window.open(url, '_blank');
          }}
          className="rounded w-full"
        >
          {confirmation?.cta?.text}
          <ExternalIcon className="ml-2 size-5" />
        </Button>
      );
    }

    return null;
  };

  const markdown = () => {
    return (
      <Markdown className="bg-grey rounded w-full h-auto max-h-96 overflow-y-scroll p-4 mb-4">
        {confirmation?.text || ''}
      </Markdown>
    );
  };

  const avatar = () => {
    return (
      <div className="flex flex-row w-full items-center justify-start gap-x-1 mb-4">
        <UserAvatar host={host} imageSize="sm" iconSize="sm" expandable />
        <span className="text-base">From your Host:</span>
        <b className="text-base">{host?.friendlyName}</b>
      </div>
    );
  };

  return (
    <div>
      <div className="rounded overflow-hidden">
        <MobileScreen>
          {showConfirmation ? (
            <div className="flex lg:flex-row flex-col w-full items-center justify-center">
              <div className="flex flex-col w-3/5 w-full h-auto items-center justify-center mx-4 bg-white border-primary border rounded p-4 mt-4">
                {avatar()}
                {markdown()}
                {cta()}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Img
                className="w-3/5 object-center rounded"
                src={experience?.bannerUrl ?? ''}
                alt={experience?.title}
              />
            </div>
          )}
        </MobileScreen>
        <DesktopScreen>
          {showConfirmation ? (
            <div className="flex lg:flex-row flex-col w-full items-center justify-center">
              <div className="w-1/2 w-full rounded overflow-hidden">
                <Img
                  src={experience?.bannerUrl ?? ''}
                  alt={experience?.title}
                  className="rounded"
                />
              </div>
              <div className="flex flex-col w-1/2 w-full h-auto items-center justify-center mx-4 bg-white border-primary border rounded p-4 mt-4">
                {avatar()}
                {markdown()}
                {cta()}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Img
                className="w-3/5 object-center rounded"
                src={experience?.bannerUrl ?? ''}
                alt={experience?.title}
              />
            </div>
          )}
        </DesktopScreen>
      </div>
    </div>
  );
}

export default MessageFromHost;
