import React, { useMemo } from 'react';
import { Loader, SwiperSlide } from '@cg/module-frontend/src/components';
import { Carrousel } from '../common';
import ExperienceCard from './ExperienceCard.tsx';
import { useExperiencesList } from './experiences.hooks.ts';

type Props = {
  previous?: boolean;
  hideCity?: boolean;
};

const ExperiencesCarrousel = React.memo(({ previous, hideCity }: Props) => {
  const { experiences, calling, nextPage, hasMore } = useExperiencesList({
    previous,
  });

  const cards = useMemo(() => {
    const result =
      experiences.map((experience) => (
        <SwiperSlide
          className="!overflow-hidden"
          key={experience.id.getValue()}
        >
          <ExperienceCard experience={experience} host={experience.host} />
        </SwiperSlide>
      )) || [];
    if (calling) {
      result.push(
        <SwiperSlide className="!overflow-hidden !w-60 !h-[50vh]" key="loader">
          <Loader loading vertical />
        </SwiperSlide>,
      );
    }
    return result;
  }, [experiences, calling]);

  return (
    <Carrousel
      hideCity={hideCity ?? false}
      title={previous ? 'Previous Experiences' : 'Upcoming Experiences'}
      hasMore={hasMore}
      fetchMore={nextPage}
    >
      {cards}
    </Carrousel>
  );
});

export default ExperiencesCarrousel;
