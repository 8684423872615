import { ReactNode, useEffect, useMemo } from 'react';
import { getHostUniqueNameParam } from '@cg/module-frontend/src/hooks/params.hooks.ts';
import {
  HostLayoutContext,
  HostLayoutContextType,
} from './HostLayoutContext.tsx';
import { fetchHostByUniqueNameHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';

type Prop = {
  children: ReactNode;
};

export function HostLayoutContextProvider({ children }: Prop) {
  const hostUniqueName = getHostUniqueNameParam();
  const fetchHostArg = { ids: { hostId: hostUniqueName } };

  const {
    data: host,
    calling: fetchingHost,
    call: fetchHost,
  } = fetchHostByUniqueNameHook(false);

  useEffect(() => {
    if (!hostUniqueName) {
      return;
    }

    fetchHost(fetchHostArg);
  }, [hostUniqueName]);

  // Create context to send over
  const context = useMemo<HostLayoutContextType>(
    () => ({
      host,
      fetchingHost,
    }),
    [host, fetchingHost],
  );

  return (
    <HostLayoutContext.Provider value={context}>
      {children}
    </HostLayoutContext.Provider>
  );
}
