import { ExperiencesStack } from '~/components';
import { ListExperiencesArgs } from '~/generated/clients/public/hosts/PublicHosts.client';
import { listExperiencesHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { Host } from '~/generated/models/Host';

type HostExperienceListProps = {
  host: Host;
  previous?: boolean;
};

function HostExperienceList({ host, previous }: HostExperienceListProps) {
  const args: ListExperiencesArgs = {
    ids: {
      hostId: host.id,
    },
    filters: {
      pageSize: previous ? 3 : 6,
    },
  };

  if (previous && args.filters) {
    args.filters.previousOnly = true;
  }

  const { data, calling, nextPage, callingMore } = listExperiencesHook(
    true,
    args,
  );

  const experiences = data?.result ?? [];
  const hasNextPage = data?.metadata?.nextPageUrl !== null;
  const hasMore = !calling && hasNextPage && experiences.length !== 0;

  return (
    <div className="w-full h-fit bg-grey rounded">
      <ExperiencesStack
        host={host}
        experiences={experiences}
        fetching={calling}
        hasMore={hasMore}
        fetchingMore={callingMore}
        nextPage={nextPage}
      />
    </div>
  );
}

export default HostExperienceList;
