import React, { useRef } from 'react';

import { Page, Content, Cover } from '@cg/module-frontend/src/components';
import RunClubsList from './components/RunClubsList';
import ExperiencesList from './components/ExperiencesList';

export const HomePage = React.memo(() => {
  const exploreRef = useRef<HTMLDivElement>(null);
  const scrollTo = () => {
    window.scrollTo({
      top: (exploreRef?.current?.offsetTop ?? 0) - 120,
      behavior: 'smooth',
    });
  };

  return (
    <Page>
      <Cover
        title="Find Your People."
        imageUrls={[
          'https://assets.joincommonground.com/site/images/banners/home_banner_1.webp',
          'https://assets.joincommonground.com/site/images/banners/home_banner_2.webp',
          'https://assets.joincommonground.com/site/images/banners/home_banner_3.webp',
        ]}
        subtitle={
          <span className="flex flex-col gap-1">
            <div>Run Clubs & Social Experiences</div>
            <div>in Vancouver, BC</div>
          </span>
        }
        cta="Find Experiences"
        ctaCallback={scrollTo}
      />
      <Content>
        <RunClubsList />
        <div ref={exploreRef} />
        <ExperiencesList />
        <hr className="mt-10 mx-10" />
        <ExperiencesList previous />
      </Content>
    </Page>
  );
});

export default HomePage;
