import { ReactNode, useEffect, useMemo } from 'react';
import {
  HostExperiencesPageContext,
  HostExperiencesPageContextType,
} from './HostExperiencesPageContext.tsx';
import { listExperiencesHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { ExperienceType } from '~/generated/models/ExperienceType';
import { useHostLayout } from '~/app/context/host';

type Prop = {
  children: ReactNode;
};

export function HostExperiencesPageContextProvider({ children }: Prop) {
  const { host } = useHostLayout();

  const {
    data: experiences,
    calling: fetchingExperiences,
    call: fetchExperiences,
  } = listExperiencesHook(false);

  useEffect(() => {
    if (!host) {
      return;
    }

    fetchExperiences({
      ids: { hostId: host.id },
      filters: {
        experienceType: ExperienceType.OneTime,
      },
    });
  }, [host]);

  // Create context to send over
  const context = useMemo<HostExperiencesPageContextType>(
    () => ({
      fetchingExperiences,
      experiences: experiences?.result || [],
    }),
    [fetchingExperiences, experiences],
  );

  return (
    <HostExperiencesPageContext.Provider value={context}>
      {children}
    </HostExperiencesPageContext.Provider>
  );
}
