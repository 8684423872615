import { Outlet } from 'react-router-dom';
import {
  Banner,
  Content,
  DesktopScreen,
  Img,
  MobileScreen,
  Page,
  VerifiedHost,
} from '@cg/module-frontend/src/components';
import classNames from 'classnames';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import React from 'react';
import { DEFAULT_BANNER_URL } from '@cg/module-frontend/src/constant';
import { useHostSocials } from '@cg/module-frontend/src/components/host/hooks';
import { useHostLayout } from '~/app/context/host';
import HostNav from './HostNav';
import ClaimClub from './ClaimClub';

export default function HostTop() {
  const { host } = useHostLayout();
  const { isMobile } = useScreenSize();
  const bannerUrl = host?.bannerUrl ?? DEFAULT_BANNER_URL;
  const [isClaiming, setIsClaiming] = React.useState(false);

  const socials = useHostSocials(
    host,
    host?.bannerUrl ? 'text-white' : 'text-primary',
  );

  return (
    <>
      {host && !host.hasUsers && (
        <ClaimClub
          show={isClaiming}
          onClose={() => setIsClaiming(false)}
          host={host}
        />
      )}
      <Page>
        {!host?.hasUsers && (
          <Banner nonStick>
            Own this Run Club?{' '}
            <button
              type="button"
              className="font-bold cursor-pointer"
              onClick={() => setIsClaiming(true)}
            >
              {' '}
              Tap to claim.
            </button>
          </Banner>
        )}
        <Content className="relative" hideGetStarted>
          <DesktopScreen>
            <div className="absolute top-4 right-0 flex space-x-2 z-10">
              {socials}
            </div>
          </DesktopScreen>
        </Content>
        <div
          className={classNames(
            'relative items-center justify-center flex flex-col z-0',
            {
              'mb-16': !isMobile,
            },
          )}
        >
          <div
            className={classNames('relative w-full', {
              'aspect-w-4 aspect-h-3': isMobile,
              'aspect-w-5 aspect-h-2': !isMobile,
            })}
          >
            <Img
              className={classNames(
                'absolute inset-0 w-full h-full object-cover',
              )}
              src={bannerUrl}
              alt={host?.friendlyName}
            />
          </div>
          <div
            className={classNames('absolute', {
              'bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-primary to-transparent opacity-50':
                host?.bannerUrl,
              'inset-0 w-full h-full object-cover bg-gradient-to-b from-secondary to-primary-darker opacity-30':
                !host?.bannerUrl,
            })}
          />
          <div
            className={classNames(
              'absolute flex flex-col items-center justify-center gap-y-2',
              {
                'bottom-8': isMobile,
                'bottom-20': !isMobile,
              },
            )}
          >
            <h3 className="text-white">Welcome to</h3>
            <h1 className="text-white flex items-center">
              {host?.friendlyName}
              <VerifiedHost host={host} className="size-6" />
            </h1>
            <MobileScreen>
              <div className="flex space-x-2 z-10">{socials}</div>
            </MobileScreen>
          </div>
          <DesktopScreen>
            <HostNav />
          </DesktopScreen>
        </div>
        <MobileScreen>
          <HostNav />
        </MobileScreen>

        <Content className="relative">
          <Outlet />
        </Content>
      </Page>
    </>
  );
}
