import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Link } from 'react-router-dom';
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  TicketIcon,
} from '@cg/module-frontend/src/icons';
import { Button, HostLabel, Img } from '@cg/module-frontend/src/components';
import React from 'react';
import { Host } from '~/generated/models/Host.ts';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';

type Props = {
  experience: DetailedExperience;
  hideHost?: boolean;
  host: Host;
};

const UpcomingExperienceCard = React.memo(
  ({ experience, host, hideHost }: Props) => {
    return (
      <div className="bg-white rounded pt-4 w-full h-fit flex flex-col">
        <span className="px-4">
          <div className="w-full relative flex flex-row grow justify-between">
            <div className="w-full grow flex flex-col">
              <h4 className="w-full line-clamp-1">{experience.title}</h4>
              <div className="py-3 flex flex-col space-y-2">
                {!hideHost && (
                  <div className="flex items-center space-x-1 text-black">
                    <HostLabel host={host} />
                  </div>
                )}
                <div className="flex items-center space-x-1">
                  <CalendarIcon className="text-primary stroke-primary" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format(
                      'ddd, MMM Do',
                    )}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <ClockIcon className="text-primary stroke-primary" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format('h:mm A')}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <LocationIcon className="text-primary stroke-primary" />
                  <span className="text-sm font-normal">
                    {experience.isSecret ? (
                      <i>Secret Location</i>
                    ) : (
                      experience?.address?.name
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-[10rem] h-full flex aspect-square rounded relative justify-center items-center">
              <Img
                alt={experience.title}
                src={experience.bannerUrl}
                className="aspect-square rounded object-cover"
              />
            </div>
          </div>
          <div className="flex flex-col justify-end mt-2">
            {experience.summary}
          </div>
          <Link
            to={`/e/${host.uniqueName}--${experience.uniqueName}`}
            className="flex my-2"
          >
            <Button
              color="secondary"
              size="sm"
              className="w-full"
              icon={<TicketIcon className="text-primary size-6" />}
            >
              <span className="text-base font-medium flex items-center">
                Tickets & Info
              </span>
            </Button>
          </Link>
        </span>
      </div>
    );
  },
);

UpcomingExperienceCard.displayName = 'UpcomingExperienceCard';

export default UpcomingExperienceCard;
