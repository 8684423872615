import { JsonAny } from '@cg/common/src/types';
import { MouseEvent } from 'react';
import Button, { ButtonProps } from '../../buttons/button/Button';

export type NextPage<S extends JsonAny> = (name: string, nextState?: S) => void;

export type ModalPageSuccessButton<S extends JsonAny> = ButtonProps & {
  text: string;
  action: (state: S) => void;
};
export type ModalPageButton<S extends JsonAny> = ButtonProps & {
  text: string;
  action: (
    nextPage: NextPage<S>,
    state: S,
    event: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

type Props<S extends JsonAny> = ModalPageButton<S> & {
  nextPage: NextPage<S>;
  state: S;
};
function ModalButton<S extends JsonAny>(props: Props<S>) {
  const { action, nextPage, text, state, ...rest } = props;
  return (
    <Button
      onClick={(event) => {
        // @ts-ignore
        action(nextPage, state, event);
      }}
      {...rest}
    >
      {text}
    </Button>
  );
}

ModalButton.displayName = 'ModalButton';
export default ModalButton;
