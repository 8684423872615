import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import { useAuth } from '@cg/module-frontend';
import { UserAvatar } from '@cg/module-frontend/src/components';
import { HomeIcon, TicketIcon } from '@cg/module-frontend/src/icons';

function BottomNavigation() {
  const { self } = useAuth();

  return (
    <div
      className={classNames('flex flex-col w-full bottom-0 fixed left-0 z-50')}
    >
      <div className="w-full bg-white lg:hidden flex justify-evenly bottom-0 left-0 py-3 shadow-[0_10px_5px_10px_rgba(0,0,0,0.3)]">
        <NavLink
          key="my-experiences"
          to="/profile/experiences"
          className={({ isActive, isPending }) =>
            classNames('text-gray relative', {
              'text-primary': isActive,
              'animate-pulse': isPending,
            })
          }
        >
          {({ isActive }) => (
            <>
              <TicketIcon className="size-6" />
              <div
                className={`absolute -bottom-3 left-0 w-full bg-primary rounded-t ${isActive ? 'opacity-100 h-2' : 'opacity-0 h-0'} duration-200 `}
              />
            </>
          )}
        </NavLink>

        <NavLink
          key="home"
          to="/"
          className={({ isActive, isPending }) =>
            classNames('text-gray relative', {
              'text-primary': isActive,
              'animate-pulse': isPending,
            })
          }
        >
          {({ isActive }) => (
            <>
              <HomeIcon className="size-6" />
              <div
                className={`absolute -bottom-3 left-0 w-full bg-primary rounded-t ${isActive ? 'opacity-100 h-2' : 'opacity-0 h-0'} duration-200 `}
              />
            </>
          )}
        </NavLink>

        <NavLink
          key="profile"
          to="/profile"
          className={({ isActive, isPending }) =>
            classNames('text-gray relative size-7', {
              'text-primary': isActive,
              'animate-pulse': isPending,
            })
          }
        >
          {({ isActive }) => (
            <>
              <UserAvatar
                user={self?.user}
                imageSize="sm"
                iconSize="sm"
                className="w-full h-full"
              />
              <div
                className={`absolute -bottom-3 left-0 w-full bg-primary rounded-t ${isActive ? 'opacity-100 h-2' : 'opacity-0 h-0'} duration-200 `}
              />
            </>
          )}
        </NavLink>
      </div>
    </div>
  );
}

export default BottomNavigation;
