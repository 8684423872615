/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { UserProfile } from '../../../models/UserProfile';
import { NewConnectionRequest } from '../../../models/NewConnectionRequest';
import { ConnectionResponseRequest } from '../../../models/ConnectionResponseRequest';

export type CancelConnectionArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: string,
    },
}

/**
 * @summary Endpoint for cancelling a connection request
 * @param {CancelConnectionArgs} args
 */
export const cancelConnection = (args: CancelConnectionArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/connections/{userId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListConnectionsArgs = {
    verifiedUser?: boolean,
}

/**
 * <p>API endpoints for managing connections</p>
 * @summary Endpoint for returning list of your connections
 */
export const listConnections = (args: ListConnectionsArgs): Promise<HttpResult<UserProfile[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/connections', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type RequestConnectionArgs = {
    verifiedUser?: boolean,
    body: NewConnectionRequest,
}

/**
 * <p>API endpoints for managing connections</p>
 * @summary Endpoint for sending a connection request
 * @param {RequestConnectionArgs} args
 */
export const requestConnection = (args: RequestConnectionArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/connections', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type RespondConnectionArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: string,
    },
    body: ConnectionResponseRequest,
}

/**
 * @summary Endpoint for accepting or rejecting a connection request
 * @param {RespondConnectionArgs} args
 */
export const respondConnection = (args: RespondConnectionArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/connections/{userId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
