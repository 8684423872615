import {
  Button,
  DesktopScreen,
  Dropdown,
  MobileScreen,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import {
  ArrowLeftIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  EditIcon,
  GlobeIcon,
  UsersIcon,
  RunIcon,
  TicketIcon,
} from '@cg/module-frontend/src/icons';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth } from '@cg/module-frontend';
import { boxConfig } from '@cg/module-frontend/src/config';
import { useUser } from '~/app/context/user/UserContext';
import UserProfileTab from './UserProfileTab';
import { ConnectionStatus } from '~/generated/models/ConnectionStatus';

enum UserTab {
  Experiences = 'experiences',
  Mutual = 'mutual',
  Contacts = 'contacts',
  RunClub = 'runclub',
}

const MAP = {
  experiences: UserTab.Experiences,
  mutual: UserTab.Mutual,
  contacts: UserTab.Contacts,
  runclub: UserTab.RunClub,
};
type ProfileTabKey = keyof typeof MAP;

function useUserProfilePathParam(): UserTab {
  const regex = /\/u\/[^]+\/(.*)$/;
  const match = window.location.href.match(regex);

  if (match?.[1]) {
    const profileKey = match[1] as ProfileTabKey;
    if (MAP[profileKey]) {
      return MAP[profileKey];
    }
  }

  return UserTab.Experiences;
}

function SideUserProfile() {
  const {
    user,
    sharedExperiences,
    sharedRunClubs,
    sharedConnections,
    requestConnection,
    cancelConnection,
    isSelf,
    error,
  } = useUser();
  const { self } = useAuth();
  const { userNickname } = useParams();
  const baseUrl = `/u/${userNickname}`;
  const tab = useUserProfilePathParam();
  const navigate = useNavigate();

  if (!userNickname) {
    navigate(-1);
    return null;
  }

  if (error) {
    window.location.href = '/404';
    return null;
  }

  return (
    <>
      <DesktopScreen>
        <div className="p-4 h-full flex flex-col">
          <div className="flex flex-row w-full justify-between items-center px-2">
            <button type="button" onClick={() => navigate(-1)}>
              <div className="flex flex-row items-center justify-center">
                <ArrowLeftIcon className="mr-2 size-4" />
                <span>Go back</span>
              </div>
            </button>
            {isSelf && (
              <button
                type="button"
                onClick={() => {
                  window.location.href = `${boxConfig.baseUrls.commonGround}/profile/details`;
                }}
              >
                <div className="flex flex-row items-center justify-center">
                  <span>Edit Profile</span>
                  <EditIcon className="ml-2 size-4" />
                </div>
              </button>
            )}
          </div>
          <div className="w-full items-center justify-center space-y-2 flex flex-col pt-16">
            <UserAvatar
              user={user}
              expandable
              iconSize="2xlg"
              imageSize="xlg"
            />
            <span className="font-semibold">
              {user?.firstName} {user?.lastName}
            </span>
          </div>
          <div className="w-full flex flex-row space-x-4 justify-center items-center pt-4">
            <Link to={baseUrl}>
              <UserProfileTab
                count={sharedExperiences.length}
                title={<TicketIcon className="me-2 size-5" />}
                isActive={tab === UserTab.Experiences}
              />
            </Link>
            <Link to={`${baseUrl}/mutual`}>
              <UserProfileTab
                count={sharedConnections.length}
                title={<UsersIcon className="me-2 size-5" />}
                isActive={tab === UserTab.Mutual}
              />
            </Link>
            <Link to={`${baseUrl}/runclub`}>
              <UserProfileTab
                count={sharedRunClubs.length}
                title={<RunIcon className="me-2 size-5" />}
                isActive={tab === UserTab.RunClub}
              />
            </Link>
          </div>
          {user?.bio && (
            <div className="flex flex-col h-fit w-full overflow-y-scroll pt-8 px-2">
              <span className="font-bold">Bio</span>
              <span>{user?.bio}</span>
            </div>
          )}
          <div className="w-full flex flex-row space-x-2 justify-center items-center mt-auto">
            {!isSelf &&
              self?.user &&
              (user?.connection?.toUser === ConnectionStatus.NotConnected ||
                user?.connection?.toUser === ConnectionStatus.Rejected) && (
                <Button
                  color="primary"
                  className="w-full mt-4"
                  onClick={requestConnection}
                >
                  <div className="flex flex-row items-center justify-center">
                    <PlusIcon className="me-2" />
                    <span>Connect</span>
                  </div>
                </Button>
              )}
            {!isSelf &&
              self?.user &&
              user?.connection?.toUser === ConnectionStatus.Accepted && (
                <div className="flex flex-row w-full justify-center items-center space-x-2">
                  <Dropdown
                    inline
                    arrowIcon={false}
                    label={
                      <Button color="secondary" className="w-full mt-4">
                        <div className="flex flex-row items-center justify-center">
                          <span>Connected</span>
                          <ChevronDownIcon className="ms-2 size-4" />
                        </div>
                      </Button>
                    }
                    placement="bottom-end"
                  >
                    <Dropdown.Item
                      onClick={() => {
                        cancelConnection({ ids: { userId: userNickname } });
                      }}
                    >
                      Remove Connection
                    </Dropdown.Item>
                  </Dropdown>
                  <Button
                    color="primary"
                    className="w-full mt-4"
                    onClick={() => navigate(`${baseUrl}/contacts`)}
                  >
                    <span>Contact</span>
                  </Button>
                </div>
              )}
            {!isSelf &&
              self?.user &&
              user?.connection?.toUser === ConnectionStatus.Pending && (
                <Button color="primary" className="w-full mt-4" disabled>
                  <div className="flex flex-row items-center justify-center">
                    <span>Pending</span>
                  </div>
                </Button>
              )}
            {isSelf && (
              <Button
                color="primary"
                className="w-full mt-4"
                onClick={() => {
                  window.location.href = `${boxConfig.baseUrls.commonGround}/profile/details`;
                }}
              >
                <span>Your Contact Info</span>
              </Button>
            )}
          </div>
        </div>
      </DesktopScreen>
      <MobileScreen>
        <div className="p-2 space-y-8 w-full flex flex-col justify-between items-baseline">
          <button type="button" onClick={() => navigate(-1)}>
            <div className="flex flex-row items-center justify-center space-x-4">
              <ChevronLeftIcon className="mr-2 size-4" />
              <span className="font-semibold text-lg">
                {user?.firstName} {user?.lastName}
              </span>
            </div>
          </button>
          <div className="flex flex-row w-full items-center justify-center space-x-2">
            <div className="w-1/4 items-center justify-center space-y-2 flex flex-col">
              <UserAvatar user={user} expandable iconSize="lg" imageSize="lg" />
            </div>
            <div className="w-3/4 flex flex-row space-x-1 justify-center items-center">
              <Link to={baseUrl}>
                <UserProfileTab
                  count={sharedExperiences.length}
                  title={<GlobeIcon className="size-4" />}
                  isActive={tab === UserTab.Experiences}
                />
              </Link>
              <Link to={`${baseUrl}/mutual`}>
                <UserProfileTab
                  count={sharedConnections.length}
                  title={<UsersIcon className="size-4" />}
                  isActive={tab === UserTab.Mutual}
                />
              </Link>
              <Link to={`${baseUrl}/runclub`}>
                <UserProfileTab
                  count={sharedRunClubs.length}
                  title={<RunIcon className="size-4" />}
                  isActive={tab === UserTab.RunClub}
                />
              </Link>
            </div>
          </div>
          {user?.bio && (
            <div className="flex flex-col">
              <span>{user?.bio}</span>
            </div>
          )}
          {!isSelf &&
            (user?.connection?.toUser === ConnectionStatus.NotConnected ||
              user?.connection?.toUser === ConnectionStatus.Rejected) && (
              <Button
                color="primary"
                className="w-full mt-4"
                onClick={requestConnection}
              >
                <div className="flex flex-row items-center justify-center">
                  <PlusIcon className="me-2" />
                  <span>Connect</span>
                </div>
              </Button>
            )}
          {!isSelf &&
            user?.connection?.toUser === ConnectionStatus.Accepted && (
              <div className="flex flex-row w-full justify-center items-center space-x-2">
                <Dropdown
                  inline
                  arrowIcon={false}
                  label={
                    <Button color="secondary" className="w-full mt-4">
                      <div className="flex flex-row items-center justify-center">
                        <span>Connected</span>
                        <ChevronDownIcon className="ms-2 size-4" />
                      </div>
                    </Button>
                  }
                  placement="bottom-end"
                >
                  <Dropdown.Item
                    onClick={() => {
                      cancelConnection({ ids: { userId: userNickname } });
                    }}
                  >
                    Remove Connection
                  </Dropdown.Item>
                </Dropdown>
                <Button
                  color="primary"
                  className="w-full mt-4"
                  onClick={() => navigate(`${baseUrl}/contacts`)}
                >
                  <span>Contact</span>
                </Button>
              </div>
            )}
          {!isSelf && user?.connection?.toUser === ConnectionStatus.Pending && (
            <Button color="primary" className="w-full mt-4" disabled>
              <div className="flex flex-row items-center justify-center">
                <span>Pending</span>
              </div>
            </Button>
          )}

          {isSelf && (
            <Button
              color="primary"
              className="w-full mt-4"
              onClick={() => navigate(`${baseUrl}/contacts`)}
            >
              <span>Your Contact Info</span>
            </Button>
          )}
        </div>
      </MobileScreen>
    </>
  );
}

export default SideUserProfile;
