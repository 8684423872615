/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { Page } from '@cg/common/src/paging/Page';
import { User } from '../../../models/User';
import { UserId } from '@cg/common/src/ids/UserId';
import { DetailedExperience } from '../../../models/DetailedExperience';
import { ExperienceHost } from '../../../models/ExperienceHost';
import { ExperienceType } from '../../../models/ExperienceType';

export type ListMutualConnectionsArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of users who have attended an event with given user.
 * @param {ListMutualConnectionsArgs} args
 */
export const listMutualConnections = (args: ListMutualConnectionsArgs): Promise<HttpResult<Page<User>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/users/{userId}/mutual', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListMutualExperiencesArgs = {
    verifiedUser?: boolean,
    ids: {
        userId: UserId,
    },
    filters?: {
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of common experiences for given user.
 * @param {ListMutualExperiencesArgs} args
 */
export const listMutualExperiences = (args: ListMutualExperiencesArgs): Promise<HttpResult<Page<DetailedExperience>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/users/{userId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
