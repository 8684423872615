import {
  Button,
  Img,
  Link,
  VerifiedHost,
} from '@cg/module-frontend/src/components';
import {
  CalendarIcon,
  DistanceIcon,
  LocationIcon,
  TimerIcon,
} from '@cg/module-frontend/src/icons';
import { DEFAULT_BANNER_URL } from '@cg/module-frontend/src/constant.ts';
import classNames from 'classnames';
import {
  usePaceRangeDom,
  useDistanceRangeDom,
} from '@cg/module-frontend/src/hooks/series.hooks';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';
import { FamiliarFacesSummary } from '../familiar';

type Props = {
  series: DetailedExperienceSeries;
  paces: string[];
  distances: number[];
  days: string;
};
const weekDays = [
  { short: 'S', long: 'Sunday' },
  { short: 'M', long: 'Monday' },
  { short: 'T', long: 'Tuesday' },
  { short: 'W', long: 'Wednesday' },
  { short: 'T', long: 'Thursday' },
  { short: 'F', long: 'Friday' },
  { short: 'S', long: 'Saturday' },
];

function RunClubCard({ series, paces, distances, days }: Props) {
  return (
    <div className="bg-white rounded p-0 w-full flex flex-col text-sm z-20  hover:shadow-md transition-shadow">
      <Link
        to={`/h/${series.host.uniqueName}`}
        track={{
          $$action: 'visitHostInstance',
          $$host: series.host.friendlyName,
          $$hostId: series.host.id.getValue(),
        }}
      >
        <div className="relative">
          <div className="rounded relative overflow-hidden w-full aspect-w-5 aspect-h-[1.5]">
            <Img
              alt={series.host.friendlyName}
              src={series.host.bannerUrl ?? DEFAULT_BANNER_URL}
              className="w-full h-full object-cover"
            />
          </div>
          <Img
            alt="User Profile"
            src={series.host.imageUrl as string}
            className={classNames(
              'absolute w-16 h-16 rounded-full border-4 border-white -bottom-6 left-4 z-20',
            )}
          />
        </div>

        <div className="relative px-4 grow flex flex-col z-0 mt-4">
          <div className="h-24 mb-3">
            <div className="my-2 line-clamp-1">
              <h4 className="flex items-center">
                {series.host.friendlyName}
                <VerifiedHost host={series.host} />
              </h4>
            </div>
            <span className="line-clamp-3">{series.host.summary}</span>
          </div>
        </div>

        <div className="bg-grey mx-4 p-4 rounded mt-2 grid grid-cols-2 gap-2">
          <div className="flex items-center">
            <DistanceIcon className="w-4 h-4 text-primary mr-2" />
            {useDistanceRangeDom(distances)}
          </div>
          <div className="flex items-center">
            <TimerIcon className="w-4 h-4 text-primary mr-2" />
            {usePaceRangeDom(paces)}
          </div>
          <div className="flex items-center">
            <LocationIcon className="w-4 h-4 text-primary mr-2" />
            <span>{series.address.neighbourhood || series.address.city}</span>
          </div>
          <div className="flex items-center">
            {/* <ClockIcon className="w-4 h-4 text-primary mr-2" /> */}
            {/* <span> */}
            {/*  {DateUtils.timeToDater(series.schedule.timeOfDay).format( */}
            {/*    'h:mm A', */}
            {/*  )} */}
            {/* </span> */}
          </div>
          <div className="flex items-center span-col-2">
            <div className="flex items-center">
              <CalendarIcon className="w-4 h-4 text-primary mr-2" />
            </div>
            <div className="flex space-x-2 text-grey-darkest">
              {weekDays.map((day, index) => (
                <div
                  key={day.long}
                  className={classNames(
                    'border border-1 rounded-full w-6 h-6 flex items-center justify-center',
                    {
                      'bg-primary text-white border-primary':
                        days[index] === '1',
                    },
                  )}
                >
                  <span key={day.long}>{day.short}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="px-4 mt-4">
          <Button color="secondary" size="sm" className="w-full">
            <span className="text-base font-medium flex items-center">
              {series.host.hasUsers ? 'Join a Run' : 'Explore Club'}
            </span>
          </Button>
        </div>
      </Link>

      <div className="items-center justify-center w-full flex mt-4 border-t">
        <FamiliarFacesSummary users={series.connections ?? []} />
      </div>
    </div>
  );
}

RunClubCard.displayName = 'RunClubCard';

export default RunClubCard;
