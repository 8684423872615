import { Tag, Img, HostLabel } from '@cg/module-frontend/src/components';
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
} from '@cg/module-frontend/src/icons';
import React, { useMemo, ReactNode } from 'react';
import { Link } from 'react-router';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { AttendingStatus } from '~/generated/models/AttendingStatus';
import { DetailedExperience } from '~/generated/models/DetailedExperience';
import { ExperienceAvailability } from '~/generated/models/ExperienceAvailability';
import { FamiliarFacesSummary } from '../familiar';
import { Host } from '~/generated/models/Host.ts';

type Props = {
  host: Host;
  experience: DetailedExperience;
  hideHost?: boolean;
};

const ExperienceCard = React.memo(({ experience, hideHost, host }: Props) => {
  const isPast = DateUtils.isPast(experience.startDate);
  const tags = useMemo(() => {
    const isAttending =
      experience.attendingStatus === AttendingStatus.Attending;
    const isWaitlisted =
      experience.attendingStatus === AttendingStatus.Waitlist;
    const soldOut = experience.availability === ExperienceAvailability.SoldOut;

    const doms: ReactNode[] = [];
    if (isAttending && isPast) {
      doms.push(<Tag key="attended" text="Attended" position="topLeft" />);
    }
    if (isAttending && !isPast) {
      doms.push(<Tag key="attending" text="Attending" position="topLeft" />);
    }
    if (isWaitlisted && !isPast) {
      doms.push(<Tag key="watlisted" text="Waitlisted" position="topLeft" />);
    }
    if (soldOut) {
      doms.push(<Tag key="soldout" text="Sold out" position="topRight" />);
    }

    return doms;
  }, [experience, isPast]);

  return (
    <div className="bg-white rounded pt-2 hover:shadow-md transition-shadow">
      <Link
        className="flex"
        to={`/e/${host.uniqueName}--${experience.uniqueName}`}
      >
        <div className="bg-white rounded px-2 pb-2 w-fit flex flex-col">
          <div className="rounded relative overflow-hidden w-full">
            {tags}
            <Img
              alt={experience.title}
              src={experience.bannerUrl}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="px-2 flex flex-col">
            <div className="my-2 h-8 line-clamp-1">
              <h4>{experience.title}</h4>
            </div>

            <div className="mb-1 h-16 text-sm line-clamp-3">
              {experience.summary}
            </div>

            <div className="flex flex-col justify-end">
              <hr className="h-1" />
              <div className="grid grid-cols-2 gap-4 text-primary my-2">
                <div className="flex items-center space-x-1">
                  <CalendarIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format(
                      'ddd, MMM Do',
                    )}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <ClockIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format('h:mm A')}
                  </span>
                </div>
                <div className="flex items-center space-x-1 col-span-2">
                  <LocationIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {experience?.address?.name || <i>Secret Location</i>}
                  </span>
                </div>
              </div>
            </div>

            {!hideHost && <HostLabel className="mb-2" host={experience.host} />}
          </div>
        </div>
      </Link>
      {/* Familiar Faces */}
      <div className="border-t border-1">
        <FamiliarFacesSummary users={experience.connections} />
      </div>
    </div>
  );
});

ExperienceCard.displayName = 'ExperienceCard';

export default ExperienceCard;
