/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { ExperienceConfirmation } from '../../../models/ExperienceConfirmation';
import { ExperienceId } from '@cg/common/src/ids/ExperienceId';
import { IsWaitlisted } from '../../../models/IsWaitlisted';
import { JoinWaitlistRequest } from '../../../models/JoinWaitlistRequest';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceHostWithTickets } from '../../../models/ExperienceHostWithTickets';
import { ExperienceHost } from '../../../models/ExperienceHost';
import { TicketStatus } from '../../../models/TicketStatus';
import { ExperienceType } from '../../../models/ExperienceType';
import { UserWithSharedExperience } from '../../../models/UserWithSharedExperience';
import { BasicUser } from '../../../models/BasicUser';
import { TicketResponse } from '../../../models/TicketResponse';
import { Ticket } from '../../../models/Ticket';
import { SelfExperienceDetail } from '../../../models/SelfExperienceDetail';
import { TicketStatusRequest } from '../../../models/TicketStatusRequest';
import { TicketId } from '@cg/common/src/ids/TicketId';

export type FetchWaitlistConfirmationArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * <p>API endpoints for waitlist of an Experience.</p>
 * @summary Endpoint for returning the waitlist of an Experience.
 * @param {FetchWaitlistConfirmationArgs} args
 */
export const fetchWaitlistConfirmation = (args: FetchWaitlistConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/confirmations/waitlist', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type IsWaitlistedArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Fetches whether user is waitlisted on experience or not
 * @param {IsWaitlistedArgs} args
 */
export const isWaitlisted = (args: IsWaitlistedArgs): Promise<HttpResult<IsWaitlisted>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/waitlist', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type JoinWaitlistArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
    body: JoinWaitlistRequest,
}

/**
 * @summary Performs the ticket checkout process
 * @param {JoinWaitlistArgs} args
 */
export const joinWaitlist = (args: JoinWaitlistArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/waitlist', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListAttendingExperiencesArgs = {
    verifiedUser?: boolean,
    filters?: {
        ticketStatus?: TicketStatus[],
        previousOnly?: boolean,
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Fetches Experiences that the logged in User is attending
 * @param {ListAttendingExperiencesArgs} args
 */
export const listAttendingExperiences = (args: ListAttendingExperiencesArgs): Promise<HttpResult<Page<ExperienceHostWithTickets>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListAttendingFamiliarFacesArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for returning list of people and count of shared experiences given an experience.
 * @param {ListAttendingFamiliarFacesArgs} args
 */
export const listAttendingFamiliarFaces = (args: ListAttendingFamiliarFacesArgs): Promise<HttpResult<UserWithSharedExperience[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/users', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListInterestedFamiliarFacesArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for returning list of people and count of shared experiences that are interested in this experience
 * @param {ListInterestedFamiliarFacesArgs} args
 */
export const listInterestedFamiliarFaces = (args: ListInterestedFamiliarFacesArgs): Promise<HttpResult<UserWithSharedExperience[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/interested', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListTicketsArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        ticketStatus?: TicketStatus[],
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Fetches tickets that the user has bought or attending for current experience
 * @param {ListTicketsArgs} args
 */
export const listTickets = (args: ListTicketsArgs): Promise<HttpResult<Page<TicketResponse>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/tickets', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type RefundTicketArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Refunds the ticket for the user
 * @param {RefundTicketArgs} args
 */
export const refundTicket = (args: RefundTicketArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/refund', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type SelfExperienceDetailsArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Returns all the details about the signed in user and this experience
 * @param {SelfExperienceDetailsArgs} args
 */
export const selfExperienceDetails = (args: SelfExperienceDetailsArgs): Promise<HttpResult<SelfExperienceDetail>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ToggleExperienceInterestArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * @summary Endpoint for hearting and unhearting an experience.
 * @param {ToggleExperienceInterestArgs} args
 */
export const toggleExperienceInterest = (args: ToggleExperienceInterestArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/interested', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateTicketStatusArgs = {
    verifiedUser?: boolean,
    ids: {
        experienceId: ExperienceId,
        ticketId: TicketId,
    },
    body: TicketStatusRequest,
}

/**
 * @summary Updates user ticket status
 * @param {UpdateTicketStatusArgs} args
 */
export const updateTicketStatus = (args: UpdateTicketStatusArgs): Promise<HttpResult<Ticket>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/experiences/{experienceId}/tickets/{ticketId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
