import React, { useMemo } from 'react';
import { ExperienceMeta } from '~/generated/models/ExperienceMeta.ts';
import { ExperienceSeriesSchedule } from '~/generated/models/ExperienceSeriesSchedule.ts';
import { Experience } from '~/generated/models/Experience.ts';
import { ExperienceSeries } from '~/generated/models/ExperienceSeries.ts';
import { ExperienceType } from '~/generated/models/ExperienceType.ts';
import { Host } from '~/generated/models/Host.ts';

/**
 * Normalizes a pace string by ensuring it includes a `:00` if seconds are missing.
 *
 * @param {string} pace - The pace string to normalize (e.g., "5" or "5:30").
 * @returns {string} - The normalized pace string (e.g., "5:00" or "5:30").
 */
export const normalizePace = (pace: string): string => {
  if (!pace.includes(':')) {
    return `${pace}:00`;
  }
  return pace;
};

/**
 * Converts a pace string into the total number of seconds.
 *
 * @param {string} pace - The pace string to convert (e.g., "5:30").
 * @returns {number} - The total seconds (e.g., 330).
 */
export const toSeconds = (pace: string): number => {
  const [minutes, seconds] = pace.split(':').map(Number);
  return minutes * 60 + (seconds || 0);
};

/**
 * Calculates sorted unique paces from a list of series.
 *
 * @param {ExperienceMeta[]} list - The list of series to process.
 * @returns {string[]} - A sorted array of unique paces.
 */
export const calculatePace = (list: ExperienceMeta[]): string[] => {
  const addMinutes = (pace: string): string =>
    pace.includes(':') ? pace : `${pace}:00`;

  return (
    list
      .map((meta) => meta?.runs?.map((r) => r?.pace))
      .flat()
      .filter(Boolean)
      // @ts-ignore
      .map(addMinutes)
      .sort((a, b) => {
        return (
          toSeconds(normalizePace(a ?? '0')) -
          toSeconds(normalizePace(b ?? '0'))
        );
      }) as string[]
  );
};

/**
 * Hook to calculate and memoize paces for a list of series.
 *
 * @param {ExperienceMeta[]} list - The list of series to process.
 * @returns {string[]} - The memoized array of unique paces.
 */
export const usePaceCalculator = (list: ExperienceMeta[]) => {
  return useMemo(() => {
    return calculatePace(list);
  }, [list]);
};

export const calculatePaceRangeDom = (paces: string[]) => {
  const minPace = paces[0];
  const maxPace = paces[paces.length - 1] ?? minPace;

  return (
    <span className="text-sm">
      {minPace === maxPace ? minPace : `${minPace}-${maxPace}`}
      <span>/km</span>
    </span>
  );
};
/**
 * Hook to generate a JSX element representing the pace range.
 *
 * @param {string[]} paces - The sorted list of paces.
 * @returns {JSX.Element} - The JSX element displaying the pace range.
 */
export const usePaceRangeDom = (paces: string[]) => {
  return useMemo(() => {
    return calculatePaceRangeDom(paces);
  }, [paces]);
};

/**
 * Calculates sorted unique distances from a list of series.
 *
 * @param {ExperienceMeta[]} list - The list of series to process.
 * @returns {number[]} - A sorted array of unique distances.
 */
export const calculateDistance = (list: ExperienceMeta[]): number[] => {
  return list
    .map((meta) => meta?.runs?.map((t) => t?.distance))
    .flat()
    .filter(Boolean)
    .filter((v, i, a) => a.indexOf(v) === i) // Ensure uniqueness
    .sort((a, b) => (a as number) - (b as number)) as number[];
};

/**
 * Hook to calculate and memoize distances for a list of series.
 *
 * @param {ExperienceMeta[]} list - The list of series to process.
 * @returns {number[]} - The memoized array of unique distances.
 */
export const useDistanceCalculator = (list: ExperienceMeta[]) => {
  return useMemo(() => {
    return calculateDistance(list);
  }, [list]);
};

export const calculateDistanceRangeDom = (distances: number[]) => {
  const minDistance = distances[0];
  const maxDistance = distances[distances.length - 1] ?? minDistance;
  return (
    <span className="text-sm">
      {minDistance === maxDistance ? (
        <>
          {minDistance}
          <span className="text-sm">km</span>
        </>
      ) : (
        <>
          {minDistance}
          <span className="text-sm">km</span>-{maxDistance}
          <span className="text-sm">km</span>
        </>
      )}
    </span>
  );
};

/**
 * Hook to generate a JSX element representing the distance range.
 *
 * @param {number[]} distances - The sorted list of distances.
 * @returns {JSX.Element} - The JSX element displaying the distance range.
 */
export const useDistanceRangeDom = (distances: number[]) => {
  return useMemo(() => {
    return calculateDistanceRangeDom(distances);
  }, [distances]);
};

/**
 * Calculates the binary representation of days across a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {string} - A binary string representing the days.
 */
export const calculateDays = (list: ExperienceSeriesSchedule[]): string => {
  return (
    list
      .map((s) => s.dayOfWeek)
      .flat()
      .filter(Boolean)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((binary) => parseInt(binary, 2))
      // eslint-disable-next-line no-bitwise
      .reduce((acc, num) => acc | num, 0)
      .toString(2)
      .padStart(7, '0')
  );
};

export const isRunClubVerified = (host?: Host | null) => {
  return host?.ownerId?.getValue() !== 'US4b4336aba2764e55f8cf90d9f5e37405';
};

/**
 * Checks if an experience is a run club.
 * @param experience
 */
export const isRunClub = (
  experience: Experience | ExperienceSeries,
): boolean => {
  return experience.type === ExperienceType.RunClub;
};
