import { useForm } from '@formspree/react';
import { FormEvent } from 'react';
import { boxConfig } from '../config';

type SubmissionData<T extends FieldValues = FieldValues> = FormData | T;
type SubmitHandler<T extends FieldValues> = (
  submission: FormEvent<HTMLFormElement> | SubmissionData<T>,
) => Promise<void>;

type InternalFormFields = {
  environment: string;
  domain: string;
};

type UserFormFields = {
  email: string;
};

type FieldValues = Record<
  string,
  string | number | boolean | null | undefined
> &
  UserFormFields;
export const useContactForm = <T extends FieldValues>(fields?: Partial<T>) => {
  const [state, submit, resetForm] = useForm<T & InternalFormFields>(
    'xpzvbezq',
  );

  const handleSubmit: SubmitHandler<T> = async (submission) => {
    if (submission instanceof Event) {
      submission.preventDefault();
      const form = submission.target as HTMLFormElement;
      const formData = new FormData(form);

      const data = Object.fromEntries(formData.entries()) as T;

      await submit({
        ...fields,
        ...data,
        environment: boxConfig.environment,
        domain: boxConfig.domain,
      });
    } else if (
      'nativeEvent' in submission &&
      submission.nativeEvent instanceof SubmitEvent
    ) {
      // @ts-ignore
      submission.preventDefault();

      const submitter = submission.nativeEvent.submitter as
        | HTMLInputElement
        | HTMLButtonElement;
      const form = submitter?.form || (submission.target as HTMLFormElement);

      if (form) {
        const formData = new FormData(form);

        // Convert FormData to object and add internal fields
        const data = Object.fromEntries(formData.entries()) as T;

        await submit({
          ...fields,
          ...data,
          environment: boxConfig.environment,
          domain: boxConfig.domain,
        });
      }
    } else {
      const typedSubmission = submission as T;
      await submit({
        ...fields,
        ...typedSubmission,
        environment: boxConfig.environment,
        domain: boxConfig.domain,
      } as T & InternalFormFields);
    }
  };

  return {
    ...state,
    handleSubmit,
    resetForm,
  };
};
