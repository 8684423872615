import React from 'react';
import classNames from 'classnames';
import { Host } from '~/generated/models/Host.ts';
import { ToolTip } from '../tooltip';
import { VerifiedIcon } from '../../icons';
import { isRunClubVerified } from '../../hooks/series.hooks.tsx';

type Props = {
  host?: Host | null;
  className?: string;
};

function VerifiedHost({ host, className }: Props) {
  if (!isRunClubVerified(host)) {
    return null;
  }
  return (
    <ToolTip content="This run club is Verified. You can sign up to their runs.">
      <VerifiedIcon className={classNames('text-blue-500 ml-1', className)} />
    </ToolTip>
  );
}

VerifiedHost.displayName = 'VerifiedHost';

export default VerifiedHost;
