import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Link } from 'react-router-dom';
import {
  CalendarIcon,
  HeartIcon,
  LocationIcon,
  TicketIcon,
} from '@cg/module-frontend/src/icons';
import { HostLabel, Img } from '@cg/module-frontend/src/components';
import { sizeToPx } from '@cg/module-frontend/src/constant.ts';
import React from 'react';
import { ExperienceHostWithTickets } from '~/generated/models/ExperienceHostWithTickets';
import { TicketStatus } from '~/generated/models/TicketStatus';

type Props = {
  experience: ExperienceHostWithTickets;
  withTickets?: boolean;
};

const ExperienceMiniCard = React.memo(({ experience, withTickets }: Props) => {
  const showTickets = ('tickets' in experience && withTickets) ?? false;

  const TicketInPossession = [
    TicketStatus.Confirmed,
    TicketStatus.Reselling,
    TicketStatus.NoShow,
  ];

  const count = (
    experience.tickets?.filter((t) => TicketInPossession.includes(t.status)) ??
    []
  ).length;
  const interested = (experience.tickets ?? []).length === 0;

  return (
    <div className="bg-white rounded px-2 py-3 w-full h-fit flex flex-col">
      <Link
        to={
          interested
            ? `/e/${experience.host?.uniqueName}--${experience.uniqueName}`
            : `/profile/experiences/${experience.host?.uniqueName}--${experience.uniqueName}`
        }
      >
        <div className="w-full relative flex flex-row grow justify-between">
          <div className="w-full grow flex flex-col px-2">
            <h4>{experience.title}</h4>
            <div className="py-3 flex flex-col space-y-2 text-primary">
              <div className="flex items-center space-x-1">
                <CalendarIcon className="text-primary stroke-primary" />
                <span className="text-sm font-normal">
                  {DateUtils.dater(experience.startDate).format(
                    'ddd, MMM DD, h:mm A',
                  )}
                </span>
              </div>
              <div className="flex items-center space-x-1">
                <LocationIcon className="text-primary stroke-primary" />
                <span className="text-sm font-normal">
                  {experience.isSecret
                    ? 'Secret Location'
                    : (experience.address?.neighbourhood ??
                      experience.address?.city)}
                </span>
              </div>
            </div>
            <div className="mt-auto grow flex flex-col justify-end">
              <div className="space-y-3">
                <HostLabel host={experience.host} />
              </div>
            </div>
          </div>
          <div className="w-[10rem] h-full flex aspect-square rounded relative justify-center items-center">
            <Img
              alt={experience.title}
              src={experience.bannerUrl}
              className="aspect-square rounded object-cover"
            />
          </div>
        </div>
      </Link>
      {showTickets && (
        <div className="p-2 pt-4 grow flex flex-col">
          <div className="w-full rounded-md border px-4 space-y-1 py-1 flex items-center justify-center">
            {interested ? (
              <>
                <HeartIcon
                  className="text-primary stroke-primary fill-primary mr-2"
                  size={sizeToPx.xs}
                />
                <span>
                  You are <strong>Interested.</strong>
                </span>
              </>
            ) : (
              <>
                <TicketIcon className="text-primary mr-2" size={sizeToPx.xs} />
                <span>
                  You have{' '}
                  <strong>
                    {count} ticket{count > 1 && 's'}.
                  </strong>
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

ExperienceMiniCard.displayName = 'ExperienceMiniCard';

export default ExperienceMiniCard;
