import { ListExperiencesArgs } from '~/generated/clients/public/experiences/PublicExperiences.client.ts';
import { listExperiencesHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks.ts';
import { DetailedExperience } from '~/generated/models/DetailedExperience';

type UseExperiencesListProps = {
  previous?: boolean;
};
const dedupeArray = (array: DetailedExperience[]): DetailedExperience[] => {
  const map = new Map();
  array.forEach((item) => map.set(item.id.getValue(), item));
  return Array.from(map.values());
};

export const useExperiencesList = ({ previous }: UseExperiencesListProps) => {
  const args: ListExperiencesArgs = {
    filters: {
      pageSize: previous ? 3 : 6,
    },
  };
  if (previous && args.filters) {
    args.filters.previousOnly = true;
  }
  const { data, calling, nextPage, callingMore } = listExperiencesHook(
    true,
    args,
  );

  const experiences = dedupeArray(data?.result ?? []);
  const hasNextPage = data?.metadata?.nextPageUrl !== null;
  const hasMore = !calling && hasNextPage && experiences.length !== 0;

  return {
    callingMore,
    experiences,
    calling,
    nextPage,
    hasMore,
  };
};
