import {
  Banner,
  Button,
  DesktopScreen,
  Content,
  Section,
  Loader,
  MobileScreen,
  Page,
} from '@cg/module-frontend/src/components';
import { Outlet } from 'react-router';
import { useUser } from '~/app/context/user/UserContext';
import { ConnectionStatus } from '~/generated/models/ConnectionStatus';
import SideUserProfile from './SideUserProfile';

function UserProfileLayout() {
  const { fetchingUser, user, respondConnection } = useUser();

  return (
    <>
      {user?.connection?.toSelf === ConnectionStatus.Accepted &&
        user?.connection?.toUser === ConnectionStatus.NotConnected && (
          <Banner>
            This user is connected with you but you are not connected with them!
          </Banner>
        )}
      {user?.connection?.toSelf === ConnectionStatus.Pending && (
        <Banner>
          <div className="w-full flex flex-row items-center space-x-4">
            <span>This person wants to connect with you!</span>
            <Button
              color="primary"
              onClick={() => {
                respondConnection({
                  ids: { userId: user?.nickname || '' },
                  body: { status: ConnectionStatus.Accepted },
                });
              }}
            >
              Accept
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                respondConnection({
                  ids: { userId: user?.nickname || '' },
                  body: { status: ConnectionStatus.Rejected },
                });
              }}
            >
              Reject
            </Button>
          </div>
        </Banner>
      )}
      <DesktopScreen>
        <Page className="hidden md:block">
          <Content>
            <Section className="pb-9 flex flex-col px-2 bg-white rounded">
              <div className="flex flex-row bg-grey rounded p-4 h-[600px] space-x-4">
                <div className="bg-white rounded w-2/5 h-full flex flex-col space-y-4">
                  <Loader loading={fetchingUser}>
                    <SideUserProfile />
                  </Loader>
                </div>
                <div className="rounded bg-grey w-3/5 h-full flex flex-col">
                  <Outlet />
                </div>
              </div>
            </Section>
          </Content>
        </Page>
      </DesktopScreen>
      <MobileScreen>
        <Page>
          <Section className="w-full h-full flex flex-col mx-auto block md:hidden">
            <div className="pb-9 flex flex-col bg-white w-full">
              <div className="bg-white px-8 w-full h-full flex flex-row">
                <Loader loading={fetchingUser}>
                  <SideUserProfile />
                </Loader>
              </div>
              <div className="bg-grey w-full h-full">
                <div className="p-4 flex-row flex w-full h-full">
                  <div className="w-full bg-grey rounded py-4 px-6">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Content />
        </Page>
      </MobileScreen>
    </>
  );
}

export default UserProfileLayout;
