import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { CalendarIcon, LocationIcon } from '@cg/module-frontend/src/icons';
import { HostLabel, Img } from '@cg/module-frontend/src/components';
import React from 'react';
import { getAddressString } from '@cg/module-frontend/src/hooks/address.hooks.ts';
import { ExperienceHostWithTickets } from '~/generated/models/ExperienceHostWithTickets';
import { DetailedExperience } from '~/generated/models/DetailedExperience';

type Props = {
  run: ExperienceHostWithTickets | DetailedExperience;
};

const RunMiniCard = React.memo(({ run }: Props) => {
  return (
    <div className="bg-white rounded px-2 py-3 w-full h-fit flex flex-col">
      <div className="w-full relative flex flex-row grow justify-between">
        <div className="w-full grow flex flex-col px-2">
          <h4>{run.title}</h4>
          <div className="py-3 flex flex-col space-y-2 text-primary">
            <div className="flex items-center space-x-1">
              <CalendarIcon className="text-primary stroke-primary" />
              <span className="text-sm font-normal">
                {DateUtils.dater(run.startDate).format('ddd, MMM DD, h:mm A')}
              </span>
            </div>
            <div className="flex items-center space-x-1">
              <LocationIcon className="text-primary stroke-primary" />
              <span className="text-sm">
                {getAddressString(run.address, { shortHanded: true })}
              </span>
            </div>
          </div>
          <div className="mt-auto grow flex flex-col justify-end">
            <div className="space-y-3">
              <HostLabel host={run.host} />
            </div>
          </div>
        </div>
        <div className="w-[10rem] h-full flex aspect-square rounded relative justify-center items-center">
          <Img
            alt={run.title}
            src={run.bannerUrl}
            className="aspect-square rounded object-cover"
          />
        </div>
      </div>
    </div>
  );
});

RunMiniCard.displayName = 'RunMiniCard';

export default RunMiniCard;
