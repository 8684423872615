import { HostLayoutContextProvider } from '~/app/context/host';
import HostTop from './HostTop.tsx';

export default function HostLayout() {
  return (
    <HostLayoutContextProvider>
      <HostTop />
    </HostLayoutContextProvider>
  );
}
