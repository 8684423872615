import {
  FormField,
  ModalPage,
  TextInput,
} from '@cg/module-frontend/src/components';
import { useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail } from '@cg/common/src/utils';
import * as React from 'react';
import { UserCheckoutRequest } from '~/generated/models/UserCheckoutRequest.ts';
import { ModalState } from './types';
import { useHostPage } from '~/pages/hosts/host/context';

export default function guestInfoModalPage(): ModalPage<ModalState> {
  const { registerForRun, registering } = useHostPage();
  const {
    register,
    getValues,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<UserCheckoutRequest>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  return {
    header: 'Continue as Guest',
    body: (
      <div className="flex flex-col w-full space-y-4">
        <FormField
          required
          id="firstName"
          label="First Name"
          errors={errors}
          register={register}
        >
          <TextInput color="primary" type="text" placeholder="First Name" />
        </FormField>
        <FormField
          required
          id="lastName"
          label="Last Name"
          errors={errors}
          register={register}
        >
          <TextInput color="primary" type="text" placeholder="Last Name" />
        </FormField>
        <FormField
          id="email"
          label="Email"
          errors={errors}
          register={register}
          registerOptions={{
            validate: (email?: string) => {
              if (!email) {
                return true;
              }

              if (isValidEmail(email)) {
                return true;
              }

              return 'Please enter a valid email.';
            },
          }}
        >
          <TextInput color="primary" placeholder="Your email address" />
        </FormField>
      </div>
    ),
    footer: {
      primary: {
        text: 'Confirm',
        loading: registering,
        disabled: !isValid || isSubmitting || !isDirty,
        action: async (nextPage, state) => {
          await registerForRun(
            getValues(),
            state.added.map((e) => e.id),
          );
          nextPage('success');
        },
      },
      secondary: {
        text: 'Go Back',
        disabled: registering,
        action: (nextPage) => nextPage('login'),
      },
    },
    name: 'guest-info',
  };
}
