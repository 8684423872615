import { createContext, useContext } from 'react';
import { ExperienceId } from '@cg/common/src/ids';
import { HostUserProfile } from '~/generated/models/HostUserProfile';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries';
import { DetailedExperience } from '~/generated/models/DetailedExperience';
import { TicketTier } from '~/generated/models/TicketTier';
import { UserCheckoutRequest } from '~/generated/models/UserCheckoutRequest';
import { ExperienceHostWithTickets } from '~/generated/models/ExperienceHostWithTickets';

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <ExperienceContextProvider>.',
  );
};

export type HostPageContextType = {
  hostUsers: HostUserProfile[];
  fetchingSeries: boolean;
  seriesExperiences: DetailedExperience[];
  series: DetailedExperienceSeries[];
  fetchingExperiences: boolean;
  experiences: DetailedExperience[];
  attendingExperiences: ExperienceHostWithTickets[];
  fetchTicketTiers: (experienceId: ExperienceId) => Promise<void>;
  ticketTiers: TicketTier[];
  registering: boolean;
  registerForRun: (
    user: UserCheckoutRequest,
    experienceIds: ExperienceId[],
  ) => Promise<void>;
};

export const HostPageContext = createContext<HostPageContextType>({
  hostUsers: [],
  fetchingSeries: false,
  seriesExperiences: [],
  series: [],
  fetchingExperiences: false,
  experiences: [],
  attendingExperiences: [],
  fetchTicketTiers: stub,
  ticketTiers: [],
  registering: false,
  registerForRun: stub,
  // cancelRegisterForRun: stub,
});

export function useHostPage() {
  return useContext(HostPageContext);
}
