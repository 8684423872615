/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The status of the connection</p>
 */
export enum ConnectionStatus {
	Pending = 'Pending',
	Accepted = 'Accepted',
	Rejected = 'Rejected',
	Blocked = 'Blocked',
	NotConnected = 'NotConnected'
}

