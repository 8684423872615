import classNames from 'classnames';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalPositions,
  ModalSizes,
} from 'flowbite-react';
import { useState, ReactNode, useEffect } from 'react';

import { JsonAny } from '@cg/common/src/types';
import ModalButton, {
  ModalPageButton,
  ModalPageSuccessButton,
  NextPage,
} from './ModalButton';
import { DynamicStringEnumKeysOf } from '../types';

export type ModalPage<S extends JsonAny = {}> = {
  header?: ReactNode;
  body: ReactNode | ((nextPage: NextPage<S>, state: S) => ReactNode);
  footer?: {
    primary?: ModalPageButton<S>;
    secondary?: ModalPageButton<S>;
    success?: ModalPageSuccessButton<S>;
  };
  name: string;
};

type Props<S extends JsonAny> = {
  pages: ModalPage<S>[];
  onClose: () => void;
  open: boolean;
  initialPage: string;
  initialState?: S;
  size?: DynamicStringEnumKeysOf<ModalSizes>;
  position?: DynamicStringEnumKeysOf<ModalPositions>;
  className?: string;
  dismissible?: boolean;
};

function MultiPageModal<S extends JsonAny = {}>({
  pages,
  initialPage,
  initialState,
  open,
  size = 'md',
  position = 'center',
  dismissible = true,
  className = '',
  onClose,
}: Props<S>) {
  const [internalState, setInternalState] = useState<S>(
    initialState ?? ({} as S),
  );

  const findPage = (state: string) => pages.find((page) => page.name === state);
  const [currPage, setCurrPage] = useState<ModalPage<S> | undefined>(
    findPage(initialPage),
  );
  useEffect(() => {
    const name = currPage?.name ?? initialPage;
    setCurrPage(findPage(name));
  }, [pages]);

  const nextPage = (name: string, state?: S) => {
    const page = findPage(name);
    if (page) {
      if (state !== undefined) {
        setInternalState(state);
      }
      setCurrPage(page);
    }
  };

  if (!currPage) {
    return (
      <Modal
        show={open}
        onClose={onClose}
        size={size}
        position={position}
        className={className}
        dismissible={dismissible}
      >
        <ModalHeader>An Error Occurred.</ModalHeader>
        <ModalBody>
          It&apos;s not you, it&apos;s us. Something went wrong
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      show={open}
      onClose={onClose}
      size={size}
      position={position}
      className={className}
      dismissible={dismissible}
    >
      {currPage.header && <ModalHeader>{currPage.header}</ModalHeader>}
      <ModalBody>
        {typeof currPage.body === 'function'
          ? currPage.body(nextPage, internalState)
          : currPage.body}
      </ModalBody>
      {currPage.footer && (
        <ModalFooter>
          {currPage?.footer?.success && (
            <ModalButton
              {...currPage.footer.success}
              action={(next, state) => {
                currPage?.footer?.success?.action(state);
                next(initialPage, initialState);
              }}
              nextPage={nextPage}
              state={internalState}
              color="primary"
              className={classNames(
                'ml-auto w-full',
                currPage.footer.success.className,
              )}
            />
          )}
          {!currPage?.footer.success && currPage.footer?.secondary && (
            <ModalButton
              {...currPage.footer.secondary}
              nextPage={nextPage}
              state={internalState}
              color="secondary"
              className={classNames(
                'ml-auto',
                currPage.footer.secondary.className,
                {
                  'flex-1':
                    currPage.footer.primary && currPage.footer.secondary,
                },
              )}
            />
          )}

          {!currPage?.footer.success && currPage.footer?.primary && (
            <ModalButton
              {...currPage.footer.primary}
              nextPage={nextPage}
              state={internalState}
              color="primary"
              className={classNames(
                'ml-auto',
                currPage.footer.primary.className,
                {
                  'flex-1':
                    currPage.footer.primary && currPage.footer.secondary,
                },
              )}
            />
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}

MultiPageModal.displayName = 'MultiPageModal';
export default MultiPageModal;
