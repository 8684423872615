import { ModalPage } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { CalendarIcon, LocationIcon } from '@cg/module-frontend/src/icons';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import { getAddressString } from '@cg/module-frontend/src/hooks/address.hooks.ts';
import classNames from 'classnames';
import { ModalState } from './types';

export default function successModalPage(
  close: () => void,
): ModalPage<ModalState> {
  return {
    body: (_, { series, added }) => {
      return (
        <div className="bg-grey flex flex-col items-center justify-center rounded p-4">
          <div className="text-6xl m-4">🎉</div>

          <h2 className="text-center mb-6">You&apos;re In!</h2>

          <div className="bg-white shadow-lg rounded p-4 w-full relative flex flex-row grow justify-between">
            <div className="w-full grow flex flex-col px-2">
              <h4>{series.title}</h4>
              <div className="py-3 flex flex-col text-primary">
                <div className="flex items-center space-x-1">
                  <span className="font-semibold text-black">
                    Your new runs:
                  </span>
                </div>

                {added.map((experience, index) => {
                  const formattedDate = DateUtils.dater(
                    experience.startDate,
                  ).format('dddd, MMM D [@] h:mmA');

                  return (
                    <div
                      className={classNames('flex flex-col text-primary py-2', {
                        'border-b': index !== added.length - 1,
                      })}
                      key={experience.id.getValue()}
                    >
                      <div className="flex items-center space-x-1">
                        <CalendarIcon className="text-primary stroke-primary" />
                        <span className="text-sm font-normal">
                          {formattedDate}
                        </span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <LocationIcon className="text-primary stroke-primary" />
                        <span className="text-sm">
                          {getAddressString(experience.address, {
                            shortHanded: true,
                            includeName: true,
                          })}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    },
    footer: {
      success: {
        text: 'Done!',
        action: close,
      },
    },
    name: 'success',
  };
}
