import { Button, ModalPage } from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { useLocation } from 'react-router-dom';
import * as React from 'react';
import { ModalState } from './types';

export default function loginModalPage(): ModalPage<ModalState> {
  const { login } = useAuth();
  const location = useLocation();

  return {
    header: 'Do You Have an Account?',
    body: (nextPage, state) => {
      const added = state.added.map((e) => e.id.getValue());

      return (
        <div className="flex flex-col items-center space-y-4">
          <Button
            className="w-4/5"
            color="primary"
            onClick={() =>
              login({
                returnTo: `${location.pathname}?autoRegister=true&seriesId=${state.series.id.getValue()}&experienceIds=${added.join(',')}`,
              })
            }
          >
            Login
          </Button>
          <Button
            className="w-4/5"
            color="secondary"
            onClick={() => nextPage('guest-info')}
          >
            Continue as Guest
          </Button>
        </div>
      );
    },
    name: 'login',
  };
}
