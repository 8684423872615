/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { UserProfile } from '../../../models/UserProfile';

export type FetchUserArgs = {
    ids: {
        userId: string,
    },
}

/**
 * <p>API endpoints for fetching user information.</p>
 * @summary Endpoint for returning a User by Id. This endpoint will return limited information if the logged in user is not connected to this person
 * @param {FetchUserArgs} args
 */
export const fetchUser = (args: FetchUserArgs): Promise<HttpResult<UserProfile>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/users/{userId}', params);

    return http.get(url, {
    });
}
