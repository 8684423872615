import React from 'react';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';
import { Faces } from '~/components';

type Props = {
  users: UserWithSharedExperience[];
};

export default function FamiliarFaces({ users }: Props) {
  return (
    <div className="bg-grey flex flex-col items-center justify-center rounded py-4">
      <h3 className="text-center">Familiar Faces</h3>
      <div className="flex w-full">
        <Faces users={users} />
      </div>
    </div>
  );
}
