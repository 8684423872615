import { Loader, ModalPage } from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useAuth } from '@cg/module-frontend';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ExperienceId } from '@cg/common/src/ids';
import { useHostPage } from '~/pages/hosts/host/context';
import { ModalState } from './types';

export default function autoRegisterModalPage(): ModalPage<ModalState> {
  const { registerForRun, fetchTicketTiers, registering } = useHostPage();
  const { self } = useAuth();
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (!self?.user) {
      return;
    }

    const start = async () => {
      const idsArray = searchParams.get('experienceIds')?.split(',') ?? [];
      await Promise.all(
        idsArray.map((id) => fetchTicketTiers(new ExperienceId(id))),
      );

      setTimeout(() => {
        setShowLoader(false);
        registerForRun(
          self.user,
          idsArray.map((id) => new ExperienceId(id)),
        );
      });
    };

    start();
  }, [searchParams, self]);

  return {
    header: 'Registering for the Runs',
    body: (nextPage) => {
      if (!registering && !showLoader) {
        nextPage('success');
      }

      return <Loader horizontal loading={showLoader || registering} />;
    },
    name: 'auto-register',
  };
}
