import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast, useAuth } from '@cg/module-frontend';
import { fetchUserHook } from '~/generated/clients/public/users/PublicUsers.hooks';
import { UserContext, UserContextType } from './UserContext';
import {
  listMutualExperiencesHook,
  listMutualConnectionsHook,
} from '~/generated/clients/private/users/PrivateUsers.hooks';
import {
  cancelConnectionHook,
  requestConnectionHook,
  respondConnectionHook,
} from '~/generated/clients/private/connections/PrivateConnections.hooks';
import { ConnectionStatus } from '~/generated/models/ConnectionStatus';
import {
  CancelConnectionArgs,
  RespondConnectionArgs,
} from '~/generated/clients/private/connections/PrivateConnections.client';
import { ExperienceType } from '~/generated/models/ExperienceType';

type UseParams = {
  userNickname: string;
};

type Prop = {
  children: ReactNode;
};

export function UserContextProvider({ children }: Prop) {
  const { self, fetchingSelf } = useAuth();
  const { userNickname } = useParams<UseParams>() as UseParams;

  const {
    data: user,
    call: fetchUser,
    calling: fetchingUser,
    error,
  } = fetchUserHook(false);
  const {
    data: sharedExperience,
    calling: fetchingSharedExperience,
    call: listSharedExperiences,
  } = listMutualExperiencesHook(false);
  const {
    data: sharedRunClubs,
    calling: fetchingSharedRunClubs,
    call: listSharedRunClubs,
  } = listMutualExperiencesHook(false);
  const {
    data: sharedConnections,
    calling: fetchingSharedConnections,
    call: listSharedConnections,
  } = listMutualConnectionsHook(false);

  const { call: requestConnection } = requestConnectionHook(false);
  const { call: cancelConnection } = cancelConnectionHook(false);

  const { call: respondConnectionCall } = respondConnectionHook(false);
  const [connectionStatus, setConnectionStatus] = useState<{
    toUser: ConnectionStatus;
    toSelf: ConnectionStatus;
  }>({
    toUser: ConnectionStatus.NotConnected,
    toSelf: ConnectionStatus.NotConnected,
  });

  useEffect(() => {
    if (user) {
      return;
    }

    fetchUser({ ids: { userId: userNickname } });
  }, [fetchingSelf]);

  useEffect(() => {
    if (!self?.user || !user) {
      return;
    }

    if (user.connection) {
      setConnectionStatus({
        toUser: user.connection.toUser,
        toSelf: user.connection.toSelf,
      });
    }

    const userArgs = {
      ids: {
        userId: user.id,
      },
    };

    listSharedExperiences({
      ...userArgs,
      filters: {
        experienceType: ExperienceType.OneTime,
      },
    });
    listSharedRunClubs({
      ...userArgs,
      filters: {
        experienceType: ExperienceType.RunClub,
      },
    });
    listSharedConnections(userArgs);
  }, [user]);

  const respondConnection = async (args: RespondConnectionArgs) => {
    await respondConnectionCall(args);
    if (args.body.status === ConnectionStatus.Rejected) {
      toast.success('Rejected their Connection Request!');
    } else if (args.body.status === ConnectionStatus.Accepted) {
      toast.success('Accepted their Connection Request!');
    }

    fetchUser({ ids: { userId: userNickname } });
  };

  const removeConnection = async (args: CancelConnectionArgs) => {
    await cancelConnection(args);
    toast.success('Connection Removed!');
    fetchUser({ ids: { userId: userNickname } });
  };

  const connect = async () => {
    await requestConnection({ body: { nickname: userNickname } });
    fetchUser({ ids: { userId: userNickname } });
  };

  // Create context to send over
  const context = useMemo<UserContextType>(
    () => ({
      user,
      isSelf: self?.user?.nickname === user?.nickname,
      fetchingUser,
      connectionStatus,
      sharedExperiences: sharedExperience?.result || [],
      fetchingSharedExperience,
      sharedRunClubs: sharedRunClubs?.result || [],
      fetchingSharedRunClubs,
      sharedConnections: sharedConnections?.result || [],
      fetchingSharedConnections,
      requestConnection: connect,
      respondConnection,
      cancelConnection: (args: CancelConnectionArgs) => removeConnection(args),
      error,
    }),
    [
      user,
      fetchingSelf,
      fetchingUser,
      connectionStatus,
      sharedExperience,
      sharedRunClubs,
      fetchingSharedExperience,
      fetchingSharedRunClubs,
      sharedConnections,
      fetchingSharedRunClubs,
      error,
    ],
  );

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
