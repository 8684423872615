import React from 'react';
import { useExperiencesList } from '~/components/experiences/experiences.hooks';
import { ExperiencesStack } from '~/components';
import ListsController from '~/pages/home/components/ListController.tsx';

type Props = {
  previous?: boolean;
};

function ExperiencesList({ previous }: Props) {
  const { experiences, calling, nextPage, hasMore, callingMore } =
    useExperiencesList({
      previous,
    });
  const title = previous ? 'Previous Experiences' : 'Upcoming Experiences';

  return (
    <ListsController
      title={title}
      stack={
        <ExperiencesStack
          experiences={experiences}
          fetching={calling && !callingMore}
          nextPage={nextPage}
          hasMore={hasMore}
          fetchingMore={callingMore}
        />
      }
      topOffset={64}
    />
  );
}

ExperiencesList.displayName = 'ExperiencesList';

export default ExperiencesList;
