/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { fetchUser, FetchUserArgs } from './PublicUsers.client';

export const fetchUserHook = (immediateCall: boolean = true, initArgs?: FetchUserArgs) => {
  return useHttp(fetchUser, { immediateCall, initArgs })
};
