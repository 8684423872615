import { UserContextProvider } from '~/app/context/user/UserContextProvider';
import UserProfileLayout from './UserProfileLayout';

export default function ProfileLayoutProvider() {
  return (
    <UserContextProvider>
      <UserProfileLayout />
    </UserContextProvider>
  );
}
