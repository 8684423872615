import React from 'react';
import { Button } from 'flowbite-react';
import { tw } from '../../utils';
import Img from '../img';
import { Swipper, SwiperSlide } from '../swipper';

export type CoverProps = {
  title: string;
  subtitle: string | React.ReactElement;
  imageUrls: string[];
  cta?: string;
  ctaCallback?: () => void;
};

/**
 * Creates a cover page icon
 */
function Cover({ title, subtitle, imageUrls, cta, ctaCallback }: CoverProps) {
  return (
    <div
      className={tw(
        'relative w-full h-[50vh] md:h-[75vh]',
        'rounded-bl-2xl rounded-br-2xl',
        'overflow-hidden',
      )}
    >
      {imageUrls.length === 1 ? (
        <Img
          noLazy
          src={imageUrls[0]}
          alt="Descriptive alt text"
          className="absolute inset-0 object-cover w-full h-full z-0"
        />
      ) : (
        <Swipper
          className="w-full h-full"
          usePagination="corner-right"
          useAutoPlay
          containerClassName="h-full w-full !absolute"
        >
          {imageUrls.map((imageUrl, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={index}>
                <Img
                  noLazy={index === 0}
                  className="object-cover w-full h-full"
                  src={imageUrl}
                />
              </SwiperSlide>
            );
          })}
        </Swipper>
      )}
      <div className="relative z-20 w-mobile lg:w-desktop h-full flex flex-col justify-end pb-10 lg:!pb-20 mx-auto">
        <h1 className="text-white">{title}</h1>
        <h4 className="mt-5 text-white font-normal">{subtitle}</h4>
        {cta && (
          <Button
            type="button"
            color="primary"
            onClick={ctaCallback}
            className="w-56 enabled:h-14 enabled:mt-8"
          >
            <span className="text-lg font-bold">{cta}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default Cover;
